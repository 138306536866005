<template>
  <div class="view-root login-container">
    <div class="view-content" style="display: flex;flex-direction: column;">
      <div style="text-align: left;width: 100%;margin-top: 160px">
        <div style="width: 160px;height: 78px">
          <img v-if="accountInfo && accountInfo.web_icon" :src="accountInfo.web_icon"
               style="width: 100%;height: 100%;object-fit: cover">
          <img v-else src="../../../assets/login-logo.png" style="width: 100%;height: 100%;object-fit: cover">
        </div>
      </div>
      <p v-if="accountInfo &&accountInfo.web_name &&accountInfo.web_name!=''"
         style="margin:25px 0px;color: #333333;font-size: 22px;font-family: PingFang SC-Bold, PingFang SC;font-weight: bold;">
        {{ accountInfo.web_name }}</p>
      <el-form :model="login_form" :rules="rules" ref="login_form" style="width: 100%;margin-top: 30px">
        <el-form-item label="" prop="user_email">
          <el-input v-model="login_form.user_email" placeholder="请输入邮箱"></el-input>
        </el-form-item>
        <el-form-item label="" prop="user_pwd">
          <el-input v-model="login_form.user_pwd" type="password" placeholder="请输入密码"
                    style="margin-top: 20px"></el-input>
        </el-form-item>
      </el-form>

      <div class="login-btn-container" @click="doLogin">
        登录
      </div>

    </div>
  </div>
</template>

<script>
import '../../../assets/common/common.css'
import {getAccount, loginByEmailNew} from '../../../api/shuodao'
import {getAccountId, setAccountId, setUserId, setUserName} from "../../../utils/store";
//测试帐号：steven.song@reachable-edu.com reachable, test@reachable-edu.com reachable
export default {
  name: "index",
  data() {
    return {
      union_id: '',
      enableLogin: false,
      login_form: {
        user_email: '',
        user_pwd: ''
      },
      rules: {
        user_pwd: [
          {required: true, message: "请输入密码", trigger: "blur"},
        ],
        user_email: [
          {required: true, message: "请输入邮箱", trigger: "blur"},
          {
            type: "email",
            message: "请输入正确邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
      },
      accountInfo: {}
    }
  },
  mounted() {

    //支持通用版本
    if (this.$route.query && this.$route.query.union_id) {
      this.union_id = this.$route.query.union_id
    }

    let account_id = getAccountId()

    if (account_id && account_id != '') {
      this.getAccountInfo(account_id, false)
    }

  },
  methods: {

    getAccountInfo(account_id, refreshData) {
      getAccount(account_id).then((res) => {
        let result = res.data
        if (result.code == 0) {
          this.accountInfo = result.data
          if (this.accountInfo && this.accountInfo.web_name && this.accountInfo.web_name != '') {
            document.title = this.accountInfo.web_name
          }
          if (this.accountInfo.web_favicon) {
            let link = this.accountInfo.web_favicon
            let $favicon = document.querySelector('link[rel="icon"]');
            if ($favicon !== null) {
              $favicon.href = link;
            }
          }
          // this.enableLogin = true
        } else {
          // this.enableLogin = false
        }
        if (refreshData) {
          this.$router.replace('/home')
        }
        //https://e.gitee.com/reachable/dashboard?issue=I722AW
        //获取公司信息,要修改访问逻辑：某个测试学校（相当于样板间），要设置单独的子域名访问，使用专用的网站名称和ICON（在管理后台里会上传），使用定制的登录页；所以UI必须要简洁、白底为主。
      })
    },

    doLogin() {

      this.$refs['login_form'].validate((valid) => {
        if (valid) {
          loginByEmailNew(this.login_form.user_email, this.login_form.user_pwd, this.union_id).then((res) => {
            let result = res.data
            let status_code = result.status_code
            if (status_code == 4) {//找不到这个用户
              this.$message.error("未找到该用户～")
              return
            }
            if (status_code == 3) {//密码不对
              this.$message.error("密码不正确～")
              return
            }
            if (status_code == 1) {// 正确
              let user_id = result.user_id
              let user_name = result.user_name
              let account_id = result.account_id
              setUserId(user_id)
              setAccountId(account_id)
              setUserName(user_name)
              this.$message.success('登录成功')
              this.getAccountInfo(account_id, true)
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.login-container {
  width: 100%;
  height: 100vh;
  background-image: url("../../../assets/login_bg.png");
  background-color: transparent;
  background-size: cover;
  background-repeat: no-repeat;
}

.login-btn-container {
  cursor: pointer;
  text-align: center;
  margin-top: 30px;
  width: 100%;
  height: 48px;
  line-height: 48px;
  background-color: #508CFF;
  border-radius: 8px;
  box-sizing: border-box;
  /*background-image: url("../../../assets/login_btn.png");*/
  /*background-color: transparent;*/
  /*background-size: cover;*/
  /*background-repeat: no-repeat;*/
  font-size: 20px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #FFFFFF;
}

/deep/ .el-input__inner {
  color: #333333;
  border-radius: 10px;
  background-color: #EFF6FF;
  border-width: 0px;
  height: 60px;
  font-size: 16px;
}

</style>
