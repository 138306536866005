import Cookies from 'js-cookie'

let env = process.env.NODE_ENV

const USER_ID = 'shuodao-user_id'
const USER_NAME = 'shuodao-user_name'
const ACCOUNT_ID = 'shuodao-account_id'
const HOME_TAB_INDEX = 'shuodao-tabIndex'
const SIGN_CACHE = 'shuodao-fwh-sign_cache'


export function setSignCache(cache) {
    if(env==='development'){
        Cookies.set(SIGN_CACHE, cache)
    }else{
        Cookies.set(SIGN_CACHE, cache)
    }
}

export function getSignCache() {

    return Cookies.get(SIGN_CACHE)
}


export function setHomeTabIndex(tabIndex) {
    if(env==='development'){
        Cookies.set(HOME_TAB_INDEX, tabIndex)
    }else{
        Cookies.set(HOME_TAB_INDEX, tabIndex)
    }
    
}

export function setAccountId(account_id) {
    // document.cookie = ACCOUNT_ID + '=' + decodeURIComponent(account_id) + ";path=/;domain=" + "shuodao"
    if(env==='development'){
        Cookies.set(ACCOUNT_ID, account_id)
    }else{
        Cookies.set(ACCOUNT_ID, account_id)
    }
}

export function getHomeTabIndex() {
    return Cookies.get(HOME_TAB_INDEX)
}

export function getAccountId() {
    // return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(ACCOUNT_ID).replace(/[-.+*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
    return Cookies.get(ACCOUNT_ID)
}

export function setUserId(user_id) {
    if(env==='development'){
        Cookies.set(USER_ID, user_id)
    }else{
        Cookies.set(USER_ID, user_id)
    }
    
}

export function getUserId() {
    return Cookies.get(USER_ID)
}

export function setUserName(user_name) {
    if(env==='development'){
        Cookies.set(USER_NAME, user_name)
    }else{
        Cookies.set(USER_NAME, user_name)
    }
    
}

export function getUserName() {
    return Cookies.get(USER_NAME)
}

export function clearUser() {
    if(env==='development'){
        Cookies.remove(USER_ID)
        Cookies.remove(USER_NAME)
        //这个不能移除掉
        // Cookies.remove(ACCOUNT_ID)
        Cookies.remove(HOME_TAB_INDEX)
    }else{
        Cookies.remove(USER_ID)
        Cookies.remove(USER_NAME)
        //这个不能移除掉
        // Cookies.remove(ACCOUNT_ID)
        Cookies.remove(HOME_TAB_INDEX)
    }
}
