<template>
  <div>
  </div>
</template>

<script>
import {getUserByUnionid, requestWXUserInfo} from '../api/shuodao'
import {getUserId, setAccountId, setUserId,setUserName} from "../utils/store";
import {Loading} from 'element-ui'

export default {
  data() {
    return {
      code: this.$route.query.code,
      loadingInstance: undefined
    }
  },
  created() {
    if (this.code) {
      this.loadingInstance = Loading.service({fullscreen: true, spinner: 'el-icon-loading'})
      requestWXUserInfo(this.code).then(res => {
        if (res.data.code === 0) {
          var result = res.data.data
          if (result.unionid) {
            var unionid = result.unionid
            let open_id = result.openid
            this.doGetUserByUnionid(open_id, unionid)
          } else {
            this.loadingInstance.close()
            this.$message.error('发现你还没有关注我们的公众号，请先关注公众号！')
          }
        } else {
          this.loadingInstance.close()
          this.$message.error('获取用户信息出错')
        }
      })
    } else {
      this.$message.error('未获取到code授权')
    }
  },
  methods: {
    doGetUserByUnionid(open_id, unionid) {
      getUserByUnionid(unionid).then(res => {
        this.loadingInstance.close()
        let result = res.data
        if (result) {
          let user = result
          let user_id = user._id.$id
          let user_name = user.user_lastName + user.user_givenName
          setUserId(user_id)
          setUserName(user_name)
          setAccountId(user.account_id)
          this.$message({
            message: '登录成功',
            type: 'success',
            duration: 1500,
            onClose: () => {
              let temp_user_id = getUserId()
              if (!temp_user_id || temp_user_id == undefined || temp_user_id == '' || temp_user_id == null) {
                this.$message.error('没有获取到用户Id')
              } else {
                this.$router.replace('/home')
              }
            }
          })
        } else {
          this.$router.replace({
            path: '/login',
            query: {
              union_id: unionid
            }
          })
          // this.$message.error('检测到你还没有账户，请先请联系学校老师！')
        }
      })
    }
  }
}
</script>

<style>

</style>
