<template>
  <div class="question-container">
    <div v-if="questionLength>0" style="width: 100%;text-align: center">
      <p class="question_cursor">第{{ (currentQuestionIndex + 1) + '/' + questionLength }}题</p>
    </div>
    <p class="question-title">{{ selectQuestion.question }}</p>
    <div style="margin-top: 15px">
      <div v-for="(option,index) in options" :key="index" style="cursor: pointer" @click="clickOption(option,index)">
        <div style="display:flex;flex-direction: row;margin-bottom: 20px">
          <div class="option-item" :style="customOptionStyle(option)"
               style="flex: 1;box-sizing: border-box;display: flex;flex-direction: row">
            <p style="flex: 1">{{ formatOptionIndexToString(index) + '&nbsp&nbsp|&nbsp' + option.value }}</p>
            <div v-if="option.reviewOption">
              <img src="../assets/answer_correct.png" style="width: 20px;height: 20px"
                   v-if="option.styleType == 'success'">
              <img src="../assets/answer_error.png" style="width: 20px;height: 20px"
                   v-else-if="option.styleType == 'failure'">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 15px;" v-if="questionLength>0">
      <div style="margin-top: 15px;display: flex;flex-direction: row;align-items: center;justify-content: center">
        <p class="last-question-btn" @click="showLastQuestion">上一题</p>
        <div style="width: 25px;"></div>
        <p class="submit-answer" @click="submitAnswer" v-if="!selectQuestion.user_answer">提交答案</p>
        <p class="next-question-btn" @click="showNextQuestion" v-if="selectQuestion.user_answer">下一题</p>
      </div>
    </div>
  </div>
</template>

<script>
import {getAudioQuestionsAndAnswers, submitAudioQuestionAnswer} from "../api/shuodao";
import {getUserId} from "../utils/store";

export default {
  name: "AudioQuestion",
  props: {
    audio_id: {
      type: String,
      default: ''
    }
  },
  watch: {
    audio_id: {
      handler(newVal, oldVal) {
        this.requestData()
      },
      immediate: true
    }
  },
  data() {
    return {
      currentQuestionIndex: 0,
      questionList: [],
      questionLength: 0,
      selectQuestion: {},
      options: []
    }
  },
  methods: {
    submitAnswer() {
      if (this.selectQuestion.user_answer) {
        this.$message.error('已经回答过问题了')
        return
      }
      let selectedIndex = -1
      for (let i = 0; i < this.options.length; i++) {
        if (this.options[i].selected) {
          selectedIndex = i
          break
        }
      }
      if (selectedIndex < 0) {
        this.$message.error('请选选择答案')
        return;
      }
      let user_answer = this.formatOptionIndexToString(selectedIndex)
      let correct = false
      if (user_answer == this.selectQuestion.answer) {
        correct = true
      }
      let params = {
        user_id: getUserId(),
        audio_id: this.audio_id,
        question_id: this.selectQuestion._id.$id,
        user_answer: user_answer,
        correct: correct,
      }
      submitAudioQuestionAnswer(params).then((res) => {
        let result = res.data
        if (result.code == 0) {
          this.$message.success('提交成功')
          this.questionList[this.currentQuestionIndex].user_answer = result.data
          this.showSelectQuestion()
        }
      })
    },
    clickOption(option, index) {
      if (option.reviewOption) {
        return
      }
      let selected = option.selected
      for (let i = 0; i < this.options.length; i++) {
        this.options[i].selected = false
      }
      this.options[index].selected = !selected
    },
    requestData() {
      getAudioQuestionsAndAnswers(this.audio_id, getUserId()).then((res) => {
        this.questionList = res.data.data
        if (this.questionList && this.questionList.length > 0) {
          this.questionLength = this.questionList.length
          this.showSelectQuestion()
        }
      })
    },
    showLastQuestion() {

      let index = this.currentQuestionIndex
      index--
      if (index < 0) {
        this.$message.success('已经是第一个题目了～')
        return
      }
      this.currentQuestionIndex = index
      this.showSelectQuestion()
    },
    showNextQuestion() {
      //判断当前题目是否已经做了
      if (!this.selectQuestion.user_answer) {
        this.$message.error('请先完成当前题目～')
        return;
      }
      let index = this.currentQuestionIndex
      index++
      if (index > this.questionLength - 1) {
        this.$message.success('已经是最后一个题目了～')
        return
      }
      this.currentQuestionIndex = index
      this.showSelectQuestion()
    },
    showSelectQuestion() {
      this.selectQuestion = this.questionList[this.currentQuestionIndex]
      //quiz_record, quest
      this.options = []
      let temp_options = []
      if (this.selectQuestion.optionA) {
        temp_options.push(this.selectQuestion.optionA)
      }
      if (this.selectQuestion.optionB) {
        temp_options.push(this.selectQuestion.optionB)
      }
      if (this.selectQuestion.optionC) {
        temp_options.push(this.selectQuestion.optionC)
      }
      if (this.selectQuestion.optionD) {
        temp_options.push(this.selectQuestion.optionD)
      }
      if (this.selectQuestion.optionE) {
        temp_options.push(this.selectQuestion.optionE)
      }

      let user_answer = this.selectQuestion.user_answer
      let question_answer = this.selectQuestion.answer
      let question_answer_index = this.formatAnswerToIndex(question_answer)
      let user_answer_index = -1
      let correct = false
      let reviewOption = false
      if (user_answer) {
        reviewOption = true
        correct = user_answer.correct
        user_answer_index = this.formatAnswerToIndex(user_answer.user_answer)
      }
      for (let i = 0; i < temp_options.length; i++) {
        let obj = {
          selected: false,
          reviewOption: reviewOption,
          value: temp_options[i]
        }
        if (reviewOption) {
          let styleType = this.getOptionStyleType(i, user_answer_index, question_answer_index, correct)
          obj['styleType'] = styleType
        }
        this.options.push(obj)
      }
    },
    // //normal success ,failure
    getOptionStyleType(index, answer, question_answer, correct) {
      //还要显示正确答案
      var styleType = 'normal'
      if (correct) {
        if (index == answer) {
          styleType = 'success'
        }
      } else {
        if (index == question_answer) {
          styleType = 'success'
        } else if (index == answer) {
          styleType = 'failure'
        }
      }
      return styleType
    },
    customOptionStyle(option) {
      let style = {}
      if (option.reviewOption) {
        let styleType = option.styleType
        if (styleType == 'normal') {
          style.background = '#FFFFFF'
          style.color = '#333333'
          style.borderColor = '#F0F0F0'
          style.borderWidth = '1px'
        } else if (styleType == 'failure') {
          style.background = '#FFF4F4'
          style.color = '#EA5350'
          style.borderColor = '#EA5350'
          style.borderWidth = '2px'
        } else if (styleType == 'success') {
          style.background = '#F3FFF2'
          style.borderColor = '#0DAF00'
          style.color = '#0DAF00'
          style.borderWidth = '2px'
        }
      } else {
        if (option.selected) {
          style.background = '#F2F5FF'
          style.borderColor = '#5592FF'
          style.color = '#5592FF'
          style.borderWidth = '2px'
        } else {
          style.background = '#FFFFFF'
          style.color = '#333333'
          style.borderColor = '#F0F0F0'
          style.borderWidth = '1px'
        }
      }
      return style
    },
    formatOptionIndexToString(index) {
      switch (index) {
        case 0:
          return 'A'
        case 1:
          return 'B'
        case 2:
          return 'C'
        case 3:
          return 'D'
        case 4:
          return 'E'
        case 5:
          return 'F'
        case 6:
          return 'G'
        case 7:
          return 'H'
      }
    },
    formatAnswerToIndex(answer) {
      switch (answer) {
        case 'A':
          return 0
        case 'B':
          return 1
        case 'C':
          return 2
        case 'D':
          return 3
        case 'E':
          return 4
        case 'F':
          return 5
        case 'G':
          return 6
      }
    }
  }
}
</script>

<style scoped lang="less">

.question_cursor {
  display: inline-block;
  text-align: center;
  width: 88px;
  height: 28px;
  line-height: 28px;
  background: #FFFFFF;
  border-radius: 6px 6px 6px 6px;
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #5592FF;
}

.option-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 13px 20px;
  box-sizing: border-box;
  width: 100%;
  background: #FFFFFF;
  font-size: 14px;
  font-weight: normal;
  color: #5592FF;
  border-radius: 6px 6px 6px 6px;
  border: 1px solid #F0F0F0;
}

.option-item:last-child {
  margin-bottom: 0px;
}

.question-container {
  width: 100%;
  height: 100%;
  padding: 20px 15px;
  box-sizing: border-box;
}

.question-title {
  margin-top: 15px;
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #333333;
  line-height: 28px;
}

.submit-answer {
  cursor: pointer;
  text-align: center;
  line-height: 42px;
  border-radius: 6px;
  flex: 2;
  height: 40px;
  background: #5592FF;
  font-size: 14px;
  color: #FFFFFF;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
}

.last-question-btn {
  cursor: pointer;
  text-align: center;
  line-height: 42px;
  flex: 1;
  height: 42px;
  background: #FFFFFF;
  border: 2px solid #5592FF;
  border-radius: 6px;
  box-sizing: border-box;
  font-size: 14px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #5592FF;
}

.next-question-btn {
  cursor: pointer;
  text-align: center;
  line-height: 42px;
  border-radius: 6px;
  flex: 2;
  height: 40px;
  background: #5592FF;
  font-size: 14px;
  color: #FFFFFF;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
}
</style>
