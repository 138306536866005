<template>
  <div>
  </div>
</template>
<script>
import {getUserId, setAccountId} from "../utils/store";
import {getAccount, getAccountIdByDomain} from '../api/shuodao'

export default {
  name: 'home',
  data() {
    return {
      account_id: this.$route.query.account_id
    }
  },
  mounted() {
    console.log('domain', document.domain)
    if (this.account_id && this.account_id != '') {
      this.checkAccountId(this.account_id)
    } else {
      this.checkDomain()
    }
  },
  methods: {

    checkAccountId(account_id) {
      getAccount(account_id).then((res) => {
        let result = res.data
        if (result.code == 0) {
          setAccountId(this.account_id)
          this.gotoNextCheck()
        } else {
          this.checkDomain()
        }
      })
    },
    checkDomain() {
      getAccountIdByDomain({domain: document.domain}).then(res => {
        if (res.data.code === 0) {
          setAccountId(res.data.data)
          this.gotoNextCheck()
        } else {
          this.gotoNextCheck()
        }
      })
    },
    gotoNextCheck() {
      let user_id = getUserId()
      console.log('user-id:' + user_id)
      if (user_id && user_id != '') {
        this.$router.replace('/home')
        return
      }
      this.gotoLogin()
    },

    gotoLogin() {
      this.$router.replace({
        path: '/login',
        query: {
          union_id: ''
        }
      })
      // const appId = 'wx4f833ba40a2c5b2c'
      // const redirectUrl = 'http://shuodao-service-web.learningfirst.cn/link'
      // window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appId + '&redirect_uri=' + redirectUrl + '&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect&connect_redirect=1'
    }
  }
}
</script>

<style scoped lang="scss">
</style>
