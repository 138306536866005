<template>
    <div class="ai-container">
        <i class="el-icon-arrow-left goBack" @click="goBack"></i>
        <div class="square-header" @click="goBack">
            <div class="txt">{{ name }}</div>
        </div>
        <div class="chatContainer">
            <div class="messageContainer" id="messageContainer">
                <div v-for="(item,index) in currentMessageList" :key="index" class="messageItem" :class="{grey:item.role==='assistant'}">
                    <img src="../../../assets/robot.png" class="imgItem" v-if="item.role==='assistant'">
                    <img src="../../../assets/user.png" class="imgItem" v-if="item.role==='user'">
                    <div class="messageItem1" v-html="changeToHtml(item.content)"></div>
                  <div v-if="!item.content" class="messageItem1">生成中...</div>
                </div>
            </div>
            <div class="inputContainer">
                <el-input v-model="currentM" class="myInput">
                    <template slot="prepend">提问</template>
                </el-input>
                <i class="el-icon-s-promotion" style="margin-left:10px;font-size: 18px;color: rgb(85,146,255);" @click="sendMessage"></i>
            </div>
        </div>
    </div>
</template>

<script>
import { getConversation,sendConversation,updateConversation,initConversationName } from '../../../api/shuodao'
import { fetchEventSource } from '@microsoft/fetch-event-source'
import { getUserId } from "../../../utils/store";
// markdown解析，代码高亮设置
window.marked.setOptions({
  highlight: function (code, language) {
    const validLanguage = window.hljs.getLanguage(language) ? language : 'javascript'
    return window.hljs.highlight(code, { language: validLanguage }).value
  }
})
export default {
    data(){
        return{
            name:'',
            currentMessageList:[],
            currentM:'',
            item:null
        }
    },
    mounted(){
        this.fetchData()
    },
    methods:{
        fetchData(){
            getConversation({id:this.$route.query.id}).then(res=>{
                if(res.data.code===0){
                    this.name = res.data.data.name
                    this.currentMessageList = res.data.data.data
                    this.item = res.data.data
                }
            })
        },
        sendMessage(){
            if (!this.currentM) return
            if (this.isGenerate) return

            this.isGenerate = true
            this.currentMessageList.push({
                role: 'user',
                content: this.currentM
            })
            this.scrollToBottom()
            this.currentM = ''
            this.currentMessageList.push({
                role: 'assistant',
                content: ''
            })
            this.scrollToBottom()
            const that = this
            let requestBody = this.currentMessageList.slice(0,this.currentMessageList.length-1).map(item=>{
                let newItem = JSON.parse(JSON.stringify(item))
                if(newItem.role==='user'){
                    newItem.content = '[以下询问内容必须和创业以及商业计划书有关，如果为无关内容，请直接回复提示不要输入无关内容，不要回复其它内容]:'+newItem.content
                }
                return newItem
            })
            const eventSource = fetchEventSource('https://web-backend-sg.reachable-edu.com/AIChat/Test.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody),
                onopen (response) {
                    console.log('连接已建立', response)
                },
                onmessage (event) {
                    if (event.data === 'Connection closed') {
                        that.isGenerate = false
                        return
                    }
                    console.log('收到消息', JSON.stringify(event.data))
                    try {
                            const result = JSON.parse(event.data)
                            if (result.time && result.content) {
                                that.currentMessageList[that.currentMessageList.length - 1].content += result.content
                            }
                        } catch (error) {
                            console.log(error)
                        }
                },
                onclose () {
                    console.log('连接已断开')
                    that.updateData()
                    that.initTitle()
                }
            })
      
        },
        goBack(){
            if(!this.isGenerate){
                this.$router.push('/AIChat')
            }
        },
        changeToHtml (data) {
            if (data.indexOf('```') !== -1) {
                const count = data.split('```').length - 1
                if (count % 2 === 0) {
                return window.marked.parse(data)
                } else {
                return window.marked.parse(data + '\n\n```')
                }
            } else {
                return window.marked.parse(data)
            }
        },
        initTitle () {
            if (this.item.hasInit) {
                return
            }
            sendConversation({
                messages: [{ role: 'user', content: `请为以下文字提供一个简短的标题（1. 标题的语言请和以下文字的语言保持一致；2. 请直接返回我标题，不要包括标题以外的任何附加文字）:${this.currentMessageList[0].content}` }]
            }).then(res => {
                if (res.data.code === 0) {
                    initConversationName({
                        id: this.item._id.$id,
                        name: res.data.data.choices[0].message.content
                    }).then(() => {
                        this.fetchData()
                    })
                }
            })
        },
        updateData () {
            updateConversation({
                id: this.item._id.$id,
                user_id: getUserId(),
                name: this.item.name,
                data: this.currentMessageList
            }).then(res => {
                console.log(11111,res)
                if (res.data.code === 0) {
                    this.fetchData()
                }
            })
        },
        scrollToBottom () {
            const dom = document.getElementById('messageContainer')
            this.$nextTick(() => {
                dom.scrollTop = dom.scrollHeight
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.ai-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: #FFFFFF;
  .square-header {
        text-align: center;
        line-height: 80px;
        height: 80px;
        width: 100%;
        background-image: url("../../../assets/square_header_new.png");
        background-repeat: no-repeat;
        background-color: transparent;
        background-size: cover;
        font-size: 20px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        .txt{
            width:70%;
            margin: 0 auto;
            white-space:nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            text-align: center;
        }
    }
    .goBack{
        font-size: 26px;
        font-weight: bold;
        position: absolute;
        left: 20px;
        color: #FFFFFF;
        top: 30px;
    }
    .chatContainer{
        height: calc(100% - 80px);
        display: flex;
        flex-direction: column;
        .messageContainer{
            height: calc(100% - 80px);
            overflow-y: scroll;
            overflow-x: hidden;
            .messageItem{
                padding: 24px 14px;
                display: flex;
            }
        }
        .inputContainer{
            width: 100%;
            height: 80px;
            border-top: 1px solid #ccc;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .grey{
            background: rgb(245,245,245);
        }
        .imgItem{
            width:34px;height:34px;margin-right:12px;margin-top:-2px;border-radius: 6px;flex-shrink: 0;
        }
    }
}
</style>
<style lang="scss">
.myInput{
    width: 88%;
    .el-input__inner{
        border: none;
        background: rgb(247,247,247);
    }
    .el-input-group__prepend{
        background: rgb(85,146,255);
        color: #fff;
        margin: 10px;
    }
}
</style>