<template>
  <div>
    <el-form :model="form" :rules="rules" ref="form" style="margin: 20px auto;width: 94%;">
      <el-form-item label="姓名" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <el-input v-model="form.email"></el-input>
      </el-form-item>
      <el-form-item label="初始密码" prop="password">
        <el-input v-model="form.password"></el-input>
      </el-form-item>
      <!--            <el-form-item label="部门">-->
      <!--                <el-select :disabled="group_id" v-model="form.group_ids" multiple :collapse-tags="true">-->
      <!--                    <el-option-->
      <!--                    v-for="item in groups"-->
      <!--                    :key="item._id.$id"-->
      <!--                    :value="item._id.$id"-->
      <!--                    :label="item.group_name"-->
      <!--                    ></el-option>-->
      <!--                </el-select>-->
      <!--            </el-form-item>-->
<!--      <el-form-item label="是否可以上传内容">-->
<!--        <el-switch-->
<!--            active-color="#3e57bc"-->
<!--            v-model="form.canUploadAudio"-->
<!--        ></el-switch>-->
<!--      </el-form-item>-->
      <!--            <el-form-item label="在职状态">-->
      <!--                <el-select v-model="form.employee_active_status">-->
      <!--                    <el-option value="在职"></el-option>-->
      <!--                    <el-option value="离职"></el-option>-->
      <!--                </el-select>-->
      <!--            </el-form-item>-->
      <div>
        <el-button type="primary" @click="confirmEdit" size="medium"
        >确认
        </el-button
        >
      </div>
    </el-form>
  </div>
</template>

<script>
import {updateUser, getAccountGroups, getAccount, getAccountIdByDomain} from '../../../api/shuodao'
import {getAccountId, getUserId, setAccountId,} from "../../../utils/store";
// 需求修改 https://e.gitee.com/reachable/dashboard?issue=I7ALPK
export default {
  data() {
    return {
      form: {
        name: '',
        email: '',
        password: '',
        group_ids: [],
        canUploadAudio: false,
        employee_active_status: '在职',
      },
      rules: {
        name: [{required: true, message: '请输入用户姓名', trigger: 'blur'}],
        email: [{required: true, message: '请输入用户邮箱', trigger: 'blur'}],
        password: [{required: true, message: '请输入初始密码', trigger: 'blur'}]
      },
      groups: [],
      account_id: this.$route.query.account_id,
      enableAddUser: false
      // group_id:this.$route.query.group_id,
    }
  },
  mounted() {

    if (this.account_id && this.account_id != '') {
      this.checkAccountId(this.account_id)
    } else {
      this.checkDomain()
    }

    // getAccountGroups(this.account_id).then((res) => {
    //     if (res.data.code === 0) {
    //         this.groups = res.data.data
    //     } else {
    //         this.groups = []
    //     }
    // })
    // if(this.group_id){
    //     this.form.group_ids = [this.group_id]
    // }
  },
  methods: {

    checkAccountId(account_id) {
      getAccount(account_id).then((res) => {
        let result = res.data
        if (result.code == 0) {
          this.enableAddUser = true
          setAccountId(this.account_id)
          this.gotoNextCheck()
        } else {
          this.checkDomain()
        }
      })
    },
    checkDomain() {
      getAccountIdByDomain({domain: document.domain}).then(res => {
        if (res.data.code === 0) {
          setAccountId(res.data.data)
          this.enableAddUser = true
          this.gotoNextCheck()
        } else {
          this.$message.error('参数错误～')
        }
      })
    },
    gotoNextCheck() {
      let account_id = getAccountId()
      getAccount(account_id).then((res) => {
        let result = res.data
        if (result.code == 0) {
          this.accountInfo = result.data
          if (this.accountInfo && this.accountInfo.web_name && this.accountInfo.web_name != '') {
            document.title = this.accountInfo.web_name
          }
          if (this.accountInfo.web_favicon) {
            let link = this.accountInfo.web_favicon
            let $favicon = document.querySelector('link[rel="icon"]');
            if ($favicon !== null) {
              $favicon.href = link;
            }
          }
        } else {
          this.$message.error('参数错误～')
        }
      })
    },

    confirmEdit() {
      if (!this.enableAddUser) {
        this.$message.error('参数错误～')
        return
      }
      this.$refs.form.validate(val => {
        if (val) {
          updateUser(
              '',
              this.form.name,
              this.form.email,
              this.form.password,
              this.account_id,
              this.form.canUploadAudio,
              this.form.group_ids,
              this.form.employee_active_status ? true : false
          ).then(res => {
            if (res.data.code === 0) {
              this.$message({
                message: '添加成功',
                type: 'success'
              })
              setAccountId(this.account_id)
              this.$router.push({path: '/login'})
            }
          })
        }
      })
    }
  }
}
</script>

<style>
.el-input__inner {
  border: 1px solid #333333;
  background: transparent;
  color: #2d57b8;
}

.el-form-item__label {
  font-weight: bold;
}

.el-tag {
  background: #deecff;
  color: #2d57b8;
}

.el-tag__close {
  background: transparent;
  color: #2d57b8;
}

.el-dialog__title {
  color: #2d58b8;
  font-weight: bold;
}

.el-dialog__close {
  display: none !important;
}

.close-btn {
  position: absolute;
  width: 28px;
  height: 28px;
  right: 30px;
  top: 30px;
  z-index: 100;
  cursor: pointer;
}

.submitBtn {
  width: 300px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  cursor: pointer;
  margin: 40px auto 20px auto;
  color: white;
  font-size: 18px;
  border-radius: 20px;
  background: linear-gradient(to right, #3053b8, #079db6);
}

.el-table tr {
  background: none;
}

.el-table th.el-table__cell {
  background: none;
}

.submitBtn {
  width: 300px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  cursor: pointer;
  margin: 40px auto 20px auto;
  color: white;
  font-size: 18px;
  border-radius: 20px;
  background: linear-gradient(to right, #3053b8, #079db6);
}
</style>
