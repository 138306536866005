<template>
  <div
      style="width: 100%;height: 100vh;display: flex;flex-direction: column;background-color: #f5f5f5;position:relative">
    <div style="flex: 1;width: 100%;height: 100%;overflow-y: auto">
      <router-view/>
    </div>
    <div v-if="showBar" class="isPlayStatus">
      <AudioPlayBar/>
    </div>
    <!--    <div v-if="showBar" class="isPlayStatus">播放中：{{ currentName }}</div>-->
    <div class="tab-container">
      <div v-for="(tab,index) in tabs" :key="index" class="tab-item" @click="handleTabChanged(index)">
        <img :src="tab.selected?tab.selectedTabIcon:tab.normalTabIcon" class="tab-icon">
        <p :class="tab.selected?'tab-title-selected':'tab-title-normal'" style="margin-top: 5px">{{ tab.title }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {getHomeTabIndex, setHomeTabIndex} from "../../../utils/store";
import AudioPlayBar from "../../../components/AudioPlayBar";

export default {
  name: "index",
  components: {
    AudioPlayBar
  },
  data() {
    return {
      currentTab: -1,
      tabs: [
        {
          selected: true,
          title: '学习',
          normalTabIcon: require('../../../assets/xuexiNoselect.png'),
          selectedTabIcon: require('../../../assets/xuexiSelect.png'),
          routerPath: '/square'
        },
        {
          selected: true,
          title: 'AI指导',
          normalTabIcon: require('../../../assets/ainoselect.png'),
          selectedTabIcon: require('../../../assets/aislect.png'),
          routerPath: '/aichat'
        },
        {
          selected: false,
          title: '我的',
          normalTabIcon: require('../../../assets/wodenoselect.png'),
          selectedTabIcon: require('../../../assets/wodeselect.png'),
          routerPath: '/userinfo'
        }
      ],
      showBar: false,
    }
  },
  watch: {
    $route() {
      this.checkShowPlayBar()
    }
  },
  mounted() {
    const tabIndex = getHomeTabIndex()
    if (tabIndex) {
      this.handleTabChanged(tabIndex)
    } else {
      this.handleTabChanged(0)
    }
    this.checkShowPlayBar()
  },
  methods: {
    checkShowPlayBar() {
      if (this.$route.path !== '/audio') {
        this.showBar = this.$GlobalAudio.isPlay
      } else {
        this.showBar = false
      }
    },
    handleTabChanged(index) {
      if (this.currentTab == index) {
        return
      }
      for (let i = 0; i < this.tabs.length; i++) {
        this.tabs[i].selected = false
      }
      this.tabs[index].selected = true
      this.currentTab = index
      let path = this.tabs[index].routerPath
      this.$router.push(path)
      setHomeTabIndex(index)
    },
  }
}
</script>

<style scoped lang="less">
.isPlayStatus {
  position: absolute;
  bottom: 53px;
  height: 86px;
  //background-color: red;
  z-index: 999;
  background: white;
  width: 100vw;
}

.tab-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 65px;
  background: #FFFFFF;
  box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.09);
}

.tab-item {
  cursor: pointer;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tab-icon {
  object-fit: cover;
  width: 24px;
  height: 24px;
}

.tab-title-normal {
  font-size: 12px;
  font-weight: normal;
  color: #999999;
}

.tab-title-selected {
  .tab-title-normal();
  color: #5592FF;
}
</style>
