<template>
  <div class="audioContainer">
    <div class="process">
      <el-slider
          @change="changeSlide"
          v-model="currentRate"
          :show-tooltip="false"
      ></el-slider>
    </div>
    <div class="time" style="display: flex;flex-direction: row;width: 100%;">
      <p style="flex: 1">{{ formatTimeLength(currentPlayLength) }}</p>
      <p>{{ formatTimeLength(currentTotalLength) }}</p>
    </div>
    <div style="margin-top: 5px;display: flex;flex-direction: row;align-items: center;width: 100%;position: relative">
      <el-dropdown placement="top-start" trigger="click" @command="handleCommand">
        <div class="rate-container">{{ showRate }}</div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :command="0.75">0.75倍速</el-dropdown-item>
          <el-dropdown-item :command="1">正常</el-dropdown-item>
          <el-dropdown-item :command="1.25">1.25倍速</el-dropdown-item>
          <el-dropdown-item :command="1.5">1.5倍速</el-dropdown-item>
          <el-dropdown-item :command="2">2倍速</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div style="position:absolute;left: 50%;transform: translateX(-50%)">
        <img
            src="../assets/play.png"
            style="width: 44px; cursor: pointer"
            v-show="!isPlay"
            @click="play"
        />
        <img
            src="../assets/pause.png"
            style="width: 44px; cursor: pointer"
            v-show="isPlay"
            @click="pause"
        />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "AudioPlayController",
  props: {
    playData: {
      type: Object,
      default: {}
    },
  },
  watch: {
    playData: {
      handler(newVal, oldVal) {
        this.startPlay()
      },
      immediate: true
    }
  },
  mounted() {
    this.$GlobalAudio_watch('currentRate', (res) => {
      this.currentRate = res
    })
    this.$GlobalAudio_watch('currentPlayLength', (res) => {
      this.currentPlayLength = res
    })
    this.$GlobalAudio_watch('currentTotalLength', (res) => {
      this.currentTotalLength = res
    })
    this.$GlobalAudio_watch('isPlay', (res) => {
      this.isPlay = res
    })
  },
  data() {
    return {
      currentPlayLength: 0,
      currentTotalLength: 0,
      currentRate: 0,
      isPlay: false,
      showRate: '正常倍速',
    }
  },
  methods: {
    resetData() {
      this.currentPlayLength = 0
      this.currentTotalLength = 0
      this.currentRate = 0
      this.isPlay = false
      this.showRate = '正常倍速'
    },
    startPlay() {
      setTimeout(() => {
        this.playAudio()
      }, 1 * 1000)
    },
    playAudio() {

      if (this.$GlobalAudio.isPlay > 0){
        this.$GlobalAudio.isPlay = true

      }else{
        this.$globalVue.$emit('stopAudio', this.playData)
        this.$globalVue.$emit('playAudio', this.playData)
      }
   
    },
    changeSlide() {
      const audio = this.$GlobalAudio.appAudioRef
      let currentTime = (this.currentRate / 100) * this.currentTotalLength
      audio.currentTime = currentTime
    },
    hexToString(str) {
      var rawStr = str.trim()
      //将中文部分转成中文
      rawStr = decodeURI(rawStr)
      var len = rawStr.length
      var curCharCode
      var resultStr = []
      var re = /[\u4E00-\u9FA5]/
      for (var i = 0; i < len; i = i + 2) {
        if (re.test(rawStr.charAt(i))) {
          //中文
          resultStr.push(rawStr.charAt(i))
          i = i - 1
        } else {
          curCharCode = parseInt(rawStr.substr(i, 2), 16)
          resultStr.push(String.fromCharCode(curCharCode))
        }
      }
      return resultStr.join('')
    },
    play() {
      
      const audio = this.$GlobalAudio.appAudioRef

      let str1 = encodeURI(this.playData.audio_list[0].address)
      
      if (audio.src == str1){

      }else{
        this.$globalVue.$emit('stopAudio', this.playData)
        this.$globalVue.$emit('playAudio', this.playData)
      }
      audio.play()
    },
    pause() {
      const audio = this.$GlobalAudio.appAudioRef
      audio.pause()
    },
    formatTimeLength(time) {
      if (time === 0) {
        return '00:00'
      } else {
        const minute = Math.floor(time / 60)
        const second = Math.floor(time % 60)
        return `${minute < 10 ? '0' + minute : minute}:${
            second < 10 ? '0' + second : second
        }`
      }
    },
    handleCommand(e) {
      this.$GlobalAudio.appAudioRef.playbackRate = e
      this.$GlobalAudio.rate = e
      switch (e) {
        case 0.75:
          this.showRate = '0.75倍速'
          break
        case 1:
          this.showRate = '正常倍速'
          break
        case 1.25:
          this.showRate = '1.25倍速'
          break
        case 1.5:
          this.showRate = '1.5倍速'
          break
        case 2:
          this.showRate = '2倍速'
          break
      }
    }
  }
}
</script>

<style scoped lang="less">

/deep/ .el-slider__bar {
  background-color: #FFA700;
}

/deep/ .el-slider__runway {
  background-color: #FFEFD0;
}

/deep/ .el-slider__button {
  border-color: #FFA700;
}

.rate-container {
  text-align: center;
  line-height: 22px;
  width: 60px;
  height: 22px;
  border-radius: 4px 4px 4px 4px;
  border: 2px solid #5592FF;
  font-size: 9px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #5592FF;
}

.audioContainer {
  padding: 10px 20px;
  box-sizing: border-box;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;

  .process {
    width: 100%;
  }

  .time {
    color: #858585;
    letter-spacing: 1px;
    font-size: 14px;
  }
}
</style>
