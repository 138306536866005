<template>
  <div class="view-root  square-container">
    <div class="square-header">
      <p>{{ accountInfo.web_name }}</p>
    </div>
    <div style="width: 100%;height: calc(100% - 80px);overflow-y: auto;background-color: white;position:relative">
      <div style="display: flex;color: #606266;height:40px;line-height:40px">
        <div v-for="item in c1" :key="item._id.$id" style="width: 33%;text-align: center;font-size:16px" @click="selectTab(item)">
          <div :class="{showCurrent:currentChannelId===item._id.$id}">{{ item.name }}</div>
        </div>
      </div>
      <div style="text-align: center;margin-bottom: 10px;">
        <el-dropdown
          placement="bottom-start"
          trigger="click"
          ref="myDropdown"
          @command="handleCommand"
        >
          <span class="el-dropdown-link" style="color:#999;">
            更多
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-container class="sort-list-container" style="display:flex;flex-wrap:wrap">
              <el-dropdown-item
                v-for="item in c2"
                :key="item._id.$id"
                class="sort-item"
                style="width: 33%;text-align: center;padding:0;font-size:16px"
                :command="item._id.$id"
              >
                <span v-text="item.name" :class="{showCurrent:currentChannelId===item._id.$id}"></span>
              </el-dropdown-item>
            </el-container>
            <div style="text-align:center;font-size:14px;color:#999;margin-top:12px">
              <span @click="scrollUp">收起<i class="el-icon-arrow-up el-icon--right"></i></span>
            </div>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div style="height:calc(100% - 70px)">
        <Channel v-if="currentChannelId" :channel_id="currentChannelId" :key="currentChannelId"/>
      </div>
    </div>
  </div>
</template>
<script>
import '../../../assets/common/common.css'
import {getAccount, getAccountSuccessionsV1} from '../../../api/shuodao'
import {getAccountId} from "../../../utils/store";
import Channel from "./Channel";
import {
  getUserId
} from "../../../utils/store";

export default {
  name: "Square",
  components: {
    Channel
  },
  data() {
    return {
      activeName: "1",
      accountInfo: {},
      channels: [],
      currentChannelId:'',
      c1:[],
      c2:[]
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      let account_id = getAccountId()
      getAccount(account_id).then((res) => {
        this.accountInfo = res.data.data

        document.title = this.accountInfo.web_name;

      })

      getAccountSuccessionsV1(account_id,getUserId()).then((res) => {
        let result = res.data.data
        for (let i = 0; i < result.length; i++) {
          let succession = result[i]
          if (succession.channel_list && succession.channel_list.length > 0) {
            for (let j = 0; j < succession.channel_list.length; j++) {
              this.channels.push(succession.channel_list[j])
            }
            if(this.channels[0]){
              this.currentChannelId = this.channels[0]._id.$id
            }
            for(let i = 0 ;i<this.channels.length;i++){
              if(i<3){
                this.c1.push(this.channels[i])
              }else{
                this.c2.push(this.channels[i])
              }
            }
          }
        }
      })
    },
    handleCommand(e){
      let index = this.channels.findIndex((channel)=>{
        return channel._id.$id === e
      })
      if(index===0){
        this.c1 = this.channels.slice(0,3)
        this.c2 = this.channels.length > 3 ? this.channels.slice(3): []
      }else if(index===this.channels.length-1){
        this.c1 = this.channels.slice(this.channels.length-3)
        this.c2 = this.channels.slice(0,this.channels.length-3)
      }else{
        this.c1 = this.channels.slice(index-1,index+2)
        this.c2 = this.channels.slice(0,index-1).concat(this.channels.slice(index+2))
      }
      this.currentChannelId = e
    },
    selectTab(item){
      let index = this.channels.findIndex((channel)=>{
        return channel._id.$id === item._id.$id
      })
      if(index===0){
        this.c1 = this.channels.slice(0,3)
        this.c2 = this.channels.length > 3 ? this.channels.slice(3): []
      }else if(index===this.channels.length-1){
        this.c1 = this.channels.slice(this.channels.length-3)
        this.c2 = this.channels.slice(0,this.channels.length-3)
      }else{
        this.c1 = this.channels.slice(index-1,index+2)
        this.c2 = this.channels.slice(0,index-1).concat(this.channels.slice(index+2))
      }
      this.currentChannelId = item._id.$id
    },
    scrollUp(){
      this.$refs.myDropdown.hide()
    }
  }
}
</script>

<style scoped lang="less">
.square-container {
  width: 100vw;
  height: 100%;
  overflow-y: auto;
}

.square-header {
  text-align: center;
  line-height: 80px;
  height: 80px;
  width: 100%;
  background-image: url("../../../assets/square_header_new.png");
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
  font-size: 20px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #FFFFFF;
}


/deep/ .el-tab-pane{
  height: calc(100vh - 200px);
  // height: 300px;
  // background-color: rebeccapurple;
  overflow-y: auto;
}
/deep/ .container-tab  .el-tabs__content{
  flex-grow: 1;
  overflow-y: scroll;
}
/deep/ .el-tabs__item {
  color: #999999;
  font-weight: normal;
}

/deep/ .el-tabs__item.is-active {
  color: #5592FF;
  font-weight: bold;
}

/deep/ .el-tabs__active-bar {
  background-color: #5592FF;
}
</style>
<style lang="scss">
.el-dropdown-menu{
    width: 100%!important;
    position: fixed;
    top: 108px!important;
    left: 0px!important;
    right: 0px!important;
    border-top: none;
    margin-top: 0!important;
    box-shadow: 0 0px 0px 0 rgba(0,0,0,.1)!important;
    font-size: 16px!important;
  }
  .showCurrent{
    color: #409EFF;
  }
  .popper__arrow{
    display: none!important;
  }
</style>
