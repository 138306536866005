<template>
  <div class="ai-container">
    <div class="square-header">{{ accountInfo.web_name }}</div>
    <div class="conversation">
      <div class="new-btn" @click="addNew">
          <i class="el-icon-plus" style="margin-right:10px;"></i>
          新建指导
      </div>
      <div v-for="item in chat_list" :key="item._id.$id" class="chatItem" @click="toChat(item)">
        <div style="display: flex;align-items: center;width: 80%;">
          <i class="el-icon-chat-square" style="font-size: 18px;margin-right: 10px;"></i>
          <el-input v-model="item.name" v-if="item.editStatus" class="txt" :id="'input'+item._id.$id" @blur="changeName(item)"></el-input>
          <div class="txt" v-if="!item.editStatus">{{ item.name }}</div>
        </div>
        <div>
          <i class="el-icon-edit edit-icon" @click.stop="editName(item)"></i>
          <i class="el-icon-delete del-icon" style="margin-left:8px" @click.stop="remove(item)"></i>
        </div>
      </div>
    </div>


    <el-dialog title="提示" :show-close="true" :visible.sync="showRemove" width="80%">
      <div style="font-size: 20px;color: black;">是否确认删除</div>


      <!-- <div style="display: flex;"> -->
      <span slot="footer" class="dialog-footer">
        <el-button  @click="showRemove=false" >取消</el-button>
        <el-button type="primary" @click="removeTrue" >确认</el-button>
      </span>
      
    </el-dialog>

  </div>


  
  <!-- <div class="quit_box" v-show="visible">
    <div class="topBox">
      <span class="tip">提示</span>
    </div>
    <div class="quit_title">{{message}}</div>
    <button class="cancel_btn" @click="leftClick">取消</button>
    <button class="confirm_btn" @click="rightClick">确认</button>
  </div> -->


</template>

<script>
import { getUserId ,getAccountId} from "../../../utils/store";
import { getUserConversationList,removeConversation,updateConversation,initConversationName,getAccount } from '../../../api/shuodao'
export default {
  data(){
    return{
      chat_list:[],
      accountInfo:{},
      showRemove:false,
      removeItem:{}
    }
  },
  mounted(){
    this.fetchData()
  },
  methods:{
    fetchData () {
      getUserConversationList({
        user_id: getUserId()
      }).then(res => {
        if (res.data.code === 0) {
          this.chat_list = res.data.data.map(item => {
            item.editStatus = false
            return item
          })
        }
      })

      let account_id = getAccountId()
      getAccount(account_id).then((res) => {
        this.accountInfo = res.data.data

        document.title = this.accountInfo.web_name;

      })
    },
    removeTrue(){

        removeConversation({
          id: this.removeItem._id.$id
        }).then(res => {
          this.showRemove = false
          this.$message.success('删除成功')
          this.fetchData()
        })
    },
    remove (item) {



      // const userAgentInfo = navigator.userAgent;
      // const Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPod"];
      // const getArr = Agents.filter((i) => userAgentInfo.includes(i));
      // const isMobile = !!getArr.length;


      if (this.$isMobile){
        this.showRemove = true
        this.removeItem = item
      }else{
        this.$confirm('是否确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          removeConversation({
            id: item._id.$id
          }).then(res => {
            this.$message.success('删除成功')
            this.fetchData()
          })
        })
      }

      
      
    },
    addNew () {
      updateConversation({
        id: '',
        user_id: getUserId(),
        name: '新的指导',
        data: []
      }).then(res => {
        if (res.data.code === 0) {
          this.$message.success('创建成功')
          this.fetchData()
        }
      })
    },
    editName(item){
      item.editStatus = true
      this.$nextTick(() => {
        document.getElementById('input'+item._id.$id).focus()
      })
    },
    changeName (item) {
      initConversationName({
        id: item._id.$id,
        name: item.name
      }).then(() => {
        this.$message.success('修改成功')
        this.fetchData()
        item.editStatus = false
      })
    },
    toChat(item){
      this.$router.push({
        path:'/aichat-item',
        query:{
          id:item._id.$id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.ai-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  .new-btn{
      width: 96%;
      height: 50px;
      line-height: 50px;
      padding-left: 20px;
      box-sizing: border-box;
      cursor: pointer;
      margin: 10px auto;
      border-radius: 8px;
      border: 1px solid #555;
    }
    .square-header {
        text-align: center;
        line-height: 80px;
        height: 80px;
        width: 100%;
        background-image: url("../../../assets/square_header_new.png");
        background-repeat: no-repeat;
        background-color: transparent;
        background-size: cover;
        font-size: 20px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
    }
    .chatItem{
      width: 92%;
      margin: 0 auto;
      height: 50px;
      line-height: 50px;
      font-size: 18px;
      color: #333;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .txt{
          width:80%;
          white-space:nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
      }
    }
}
</style>