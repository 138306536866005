<template>
  <div class="view-root" style="height: 100vh">
    <Header :title="title" :left-click="gotoBack"/>
    <div class="about-us" @click="goAboutUs">
      <p style="flex: 1">关于我们</p>
      <img src="../../../assets/icon_right_arrow.png" style="width: 14px;height: auto">
    </div>
  </div>
</template>

<script>
import Header from "../../../components/Header";
import '../../../assets/common/common.css'

export default {
  name: "Settings",
  components: {
    Header
  },
  data() {
    return {
      title: '设置'
    }
  },
  methods: {
    gotoBack() {
      this.$router.go(-1)
    },
    goAboutUs() {
      this.$router.push('about-us')
    }
  }
}
</script>

<style scoped>
.about-us {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
  background-color: white;
  width: 100%;
  height: 50px;
  padding: 0px 15px;
  box-sizing: border-box;
  color: #454651;
  font-size: 16px;
}

</style>
