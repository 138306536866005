<template>
  <div style="width: 100%;overflow-y: auto;height: 100%;">
    <div v-for="(channelData,index) in dataList" :key="index" class="channel-item" @click="gotoTargetView(channelData)">
      <div class="audio-item">
        <div  style="object-fit: cover">

          <img v-if="channelData.type=='audio'" :src='formatAudioAddress(channelData.logoAddress)' class="channel-logo">
          <img v-else :src='formatAlbumAddress(channelData.cover_pic)' class="channel-logo">

          <div  v-if="channelData.type=='audio' && channelData.bottomType == 1" class="typeAnswer">已答完</div>
          <div  v-if="channelData.type=='audio' && channelData.bottomType == 2" class="typeListen">已听完</div>

        </div>



        <div style="flex: 1;padding-left: 15px;padding-right: 10px;box-sizing: border-box;width: calc( 100% - 80px);" id="biaoqianId">
          <p class="channel-name">{{ channelData.name }}</p>
          <div style="margin-top: 5px;width: 100%;">
            <p v-if="channelData.type == 'audio'" class="channel-publisher">{{channelData.intro}}</p>
            <div v-else>
              <p class="audio-count">共{{ channelData.audio_ids.length }}个音频</p>
            </div>

            <div v-if="channelData.type == 'audio'" class="biaoqian">

              <div v-for="(tag,index) in channelData.tags" :key="index" class="biaoqianItam" >{{tag}}</div>

            </div>

          </div>
        </div>
      </div>
      <div class="empty-line"></div>
    </div>
  </div>
</template>

<script>
import {getAlbumOrAudioListByChannelIdV2, getAudioListByUserAndChannelId ,getAlbumOrAudioListByChannelId} from '../../../api/shuodao'
import {getUserId} from "../../../utils/store";
import {formatDate} from "../../../utils/date";
import {Loading} from 'element-ui'

export default {
  name: "Channel",
  props: {
    channel_id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dataList: []
    }
  },
  watch: {
    channel_id: {
      handler(newVal, oldVal) {
        this.fetchData()
      },
      immediate: true
    }
  },
  mounted() {
  },
  methods: {

    gotoTargetView(audio) {
      if (audio.type == 'audio') {
        this.$router.push({
          path: 'audio',
          query: {
            audio_id: audio.audio_id,
            album_id: audio.album_id
          }
        })
      } else {
        this.$router.push({
          path: 'album',
          query: {
            album_id: audio._id.$id
          }
        })
      }
    },

    formatAlbumDate(create_time) {
      return formatDate(new Date(create_time * 1000), 'yyyy/MM-dd')
    },

    formatUid(uid) {
      return formatDate(new Date(uid), 'yyyy/MM-dd')
    },

    formatAudioAddress(logoAddress) {
      switch (logoAddress) {
        case 'head7.png':
          return require('../../../assets/head7.png')
        case 'head6.png':
          return require('../../../assets/head6.png')
        case 'head5.png':
          return require('../../../assets/head5.png')
        case 'head4.png':
          return require('../../../assets/head4.png')
        case 'head3.png':
          return require('../../../assets/head3.png')
        case 'head2.png':
          return require('../../../assets/head2.png')
        case 'head1.png':
          return require('../../../assets/head1.png')
      }
      return require('../../../assets/head1.png')
    },
    formatAlbumAddress(cover_pic) {

      if (cover_pic){

        return cover_pic
      }
     
      return require('../../../assets/album_default.png')
    },

    async fetchData() {

      let loadingInstance = Loading.service({
        fullscreen: true,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.8)'
      })

      let user_id = getUserId()


      let dataList = await getAlbumOrAudioListByChannelIdV2(this.channel_id, user_id)



      // let albumList = await getAlbumAudioListByUserAndChannelId(this.channel_id, user_id)
      let albumListData = dataList.data.data.albumArr
      for (let i = 0; i < albumListData.length; i++) {
        let album = albumListData[i]
        album['type'] = 'album'
        this.dataList.push(album)
      }
      // let audioList = await getAudioListByUserAndChannelId(this.channel_id, user_id)
      let audioListData = dataList.data.data.audioArr

      console.log(12313,audioListData)


      for (let i = 0; i < audioListData.length; i++) {
        let audioData = audioListData[i]
        if (audioData.audio_list && audioData.audio_list.length > 0) {
          let value = this.createData(audioData, 'audio', 0, '', '')
          this.dataList.push(value)
        }
      }


      console.log(123,this.dataList)

      loadingInstance.close()
    },
    createData(audioData, type, pos, albumName, album_id) {

      let first_audio = audioData.audio_list[0]
      let audio_url = first_audio.address
      let audio_name = first_audio.name
      let audio_uid = first_audio.uid
      let audio_status = first_audio.status

      let audio_intro = audioData.intro

      let logoAddress = audioData.logoAddress
      let content_type = audioData.content_type
      let audio_id = audioData._id.$id
      let name = audioData.name

      let is_listen = audioData.is_listen
      let is_answer = audioData.is_answer

      let bottomType = 3 // 1答完 2听完 3啥也没有
      if (is_answer == true){
        bottomType = 1;
      }else{
        if (is_listen == true){
          bottomType = 2;
        }
      }

      let hasNote = audioData.note != null

      let tags = []

      if (audioData.tags.length > 3){

          tags.push(audioData.tags[0])
          tags.push(audioData.tags[1])
          tags.push(audioData.tags[2])

      }else{

        for (let i = 0; i < audioData.tags.length; i++) {

            tags.push(audioData.tags[i])

        }

      }

      //算宽度 是否超过
      let strLength = ''

      for (let i = 0; i < tags.length; i++) {

        let str =  tags[i];
        strLength = strLength + str
      }

      var div = document.getElementById("biaoqianId");
      // var w = div.offsetWidth;    // 返回元素的总宽度
      var w = window.screen.availHeight;    // 返回元素的总宽度


      let newTags = []
      if (strLength.length * 12 +60  > w){
        for (let i = 0; i < tags.length -1; i++) {
          newTags.push(tags[i])
        }
      }else{
        for (let i = 0; i < tags.length; i++) {
          newTags.push(tags[i])
        }
      }


      const data = {
        audio_url: audio_url,
        audio_name: audio_name,
        audio_uid: audio_uid,
        audio_status: audio_status,
        intro:audio_intro,
        type: type,
        logoAddress: logoAddress,
        content_type: content_type,
        audio_id: audio_id,
        name: name,
        hasNote: hasNote,
        audio_in_album_index: (pos + 1),
        album_name: albumName,
        album_id: album_id,
        tags:newTags,
        bottomType:bottomType
      }
      return data
    }
  }
}
</script>

<style scoped lang="less">

.audio-count {
  font-size: 13px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}

.channel-publisher {
  color: #7b7b7b;
  font-size: 14px;
  line-height: 20px;
  // width: 20px;
  max-width: 100%;
  // margin-right: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.biaoqianItam{
  background-color: #E6EFFF;
  float: left;
  margin-top: 5px;
  margin-left: 5px;
  color: #5592FF;
  padding: 5px;
  font-size: 12px;
  border-radius: 2px;

}
.biaoqian{
  width: 100%;
  margin-left: -5px;
  margin-top: 5px;
  // background-color: red;
  float: left;
}
.channel-name {
  margin-top: 0px;
  font-size: 16px;
  line-height: 28px;
  font-family: PingFang SC-Bold, PingFang SC;
  color: #333333;
}

.channel-logo {
  width: 65px;
  height: 65px;
  object-fit: cover
}

.channel-item {
  margin: 15px 0px;
  padding: 0px 15px;
  display: flex;
  flex-direction: column;
}

.audio-item {
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
}

.empty-line {
  margin-top: 15px;
  width: 100%;
  height: 1px;
  background-color: #EFEFEF;
}

.typeAnswer{
  clear: both;
  height: 20px;
  width: 50px;
  margin-left: 7.5px;
  color: #00C672;
  border-radius: 5px;
  line-height: 20px;
  font-size: 12px;
  text-align: center;
  background-color: #D5FFED;
}

.typeListen{
  clear: both;
  height: 20px;
  width: 50px;
  margin-left: 7.5px;
  color: #FFA700;
  border-radius: 5px;
  line-height: 20px;
  font-size: 12px;
  text-align: center;
  background-color: #FFF1D6;
}

</style>
