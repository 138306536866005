<template>
  <div class="header-container" id="header-container">
    <img v-if="leftArrowType==0" src="../assets/arrow_black_left.png" class="btn-left" @click="goBack">
    <img v-if="leftArrowType==1" src="../assets/arrow_white_left.png" class="btn-left" @click="goBack">
    <img v-if="leftArrowType==2" src="../assets/arrow_gray_left.png" class="btn-left-1" @click="goBack">
    <p class="header-title" id="header-title">{{ title }}</p>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {
    title: {
      type: String,
      default: ''
    },
    leftClick: {
      type: Function,
      default: null
    },
    leftArrowType: {
      type: Number,
      default: 0
    },
    titleColor: {
      type: String,
      default: '#000000'
    },
    bgColor: {
      type: String,
      default: '#ffffff'
    }
  },
  watch: {
    titleColor: {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          let title = document.getElementById('header-title')
          if (title) {
            title.style.color = newVal
          }
        })
      },
      immediate: true
    },
    bgColor: {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          let container = document.getElementById('header-container')
          if (container) {
            container.style.backgroundColor = newVal
          }
        })
      },
      immediate: true
    }
  },
  methods: {
    goBack() {
      this.leftClick()
    },
  }
}
</script>

<style scoped>

.header-container {
  padding-top: 20px;
  background-color: white;
  width: 100%;
  height: 52px;
  line-height: 52px;
  position: relative;
}
.btn-left-1 {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  width: 15px;
  height: 25px;
}


.btn-left {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
}

.header-title {
  text-align: left;
  width: 80%;
  position: absolute;
  color: black;
  font-size: 18px;
  line-height: 25px;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);

}

</style>
