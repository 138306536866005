import Vue from 'vue'

let GlobalAudio = {
    appAudioRef: undefined,
    rate: 1,
    listenLength: 0,
    currentPlayLength: 0,
    currentTotalLength: 0,
    currentRate: 0,
    isPlay: false,
    audioData:undefined
}
Vue.prototype.$GlobalAudio_watch = (objItem, callback) => {
    Object.defineProperty(GlobalAudio, objItem, {
        get: function () {
            return this.value
        },
        set: function (newVal) {
            this.value = newVal
            callback(this.value)
        }
    })
}

export default GlobalAudio
