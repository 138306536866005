<template>
  <div id="app">
    <router-view></router-view>
    <audio ref="audioRefApp" style="display: none"/>
  </div>
</template>
<script>
// https://developers.weixin.qq.com/doc/offiaccount/OA_Web_Apps/Wechat_Open_Tag.html
// <wx-open-audio
//     ref="audioRefApp"
//         :title="title"
// src="http://xxx.xxx.com/.mp3"
// @canplay="canplay"
// @pause="pause"
// @seeked="seeked">

import {audioRecord} from "./api/shuodao";
import {getUserId} from "./utils/store";

export default {
  name: 'app',
  data() {
    return {
      audioTimer: '',
      checkTimer: '',
    }
  },
  beforeDestroy() {
    this.endAudioTimer()
    this.cleanCheckTimer()
  },
  mounted() {
    this.$GlobalAudio.appAudioRef = this.$refs['audioRefApp']
    // this.$globalVue.$on('playAudio', this.startPlayAudioByWXOpenAudio)
    this.$globalVue.$on('playAudio', this.playAudio)
    this.$globalVue.$on('stopAudio', this.stopAudio)
  },
  methods: {
    startPlayAudioByWXOpenAudio() {
      const audio = this.$GlobalAudio.appAudioRef
      this.$GlobalAudio.rate = 1
      this.$GlobalAudio.listenLength = 0
      this.$GlobalAudio.currentPlayLength = 0
      this.$GlobalAudio.currentTotalLength = 0
      this.$GlobalAudio.currentRate = 0
      this.$GlobalAudio.isPlay = false

      let that = this

      audio.addEventListener('timeupdate', function (event) {
        const state = event.detail;
        that.$GlobalAudio.currentPlayLength = audio.currentTime

        if (that.$GlobalAudio.currentTotalLength > 0) {
          that.$GlobalAudio.currentRate =
              (state.currentTime / that.$GlobalAudio.currentTotalLength) * 100
        }
      })
      audio.addEventListener('pause', function (event) {
        // 停止播放
        that.$GlobalAudio.isPlay = false
      });
      audio.addEventListener('play', function (event) {
        // 开始播放
        that.$GlobalAudio.isPlay = true
      });
      audio.addEventListener('canplay', function (event) {
        // 可随时播放
        const state = event.detail;
        that.$GlobalAudio.currentTotalLength = state.duration
      });
      audio.addEventListener('durationchange', function (event) {
        // 获取到音频长度
        const state = event.detail;
        that.$GlobalAudio.currentTotalLength = state.duration
      });
      audio.addEventListener('loadedmetadata', function (event) {
        // 音频meta信息只有长度，因此loadedmetadata会接着马上触发
        const state = event.detail;
        that.$GlobalAudio.currentTotalLength = state.duration
      });
      audio.addEventListener('ended', function (event) {
        that.$GlobalAudio.isPlay = false
      });
      audio.addEventListener('error', function (event) {
        const error = event.detail;
        console.log('error :'+error.errMsg);
        that.$GlobalAudio.isPlay = false
      });
    },
    stopAudio() {
      this.resetAudioData()
    },
    resetAudioData() {
      this.$GlobalAudio.rate = 1
      this.$GlobalAudio.listenLength = 0
      this.$GlobalAudio.currentPlayLength = 0
      this.$GlobalAudio.currentTotalLength = 0
      this.$GlobalAudio.currentRate = 0
      this.$GlobalAudio.isPlay = false
      const audio = this.$refs['audioRefApp']
      audio.pause()
      this.endAudioTimer()
      this.cleanCheckTimer()
    },
    playAudio(audioData) {
      //重制一下数据
      this.resetAudioData()
      const audio = this.$refs['audioRefApp']
      // 开始现在的
      audio.src = audioData.audio_list[0].address
      this.$GlobalAudio.name = audioData.name
      this.$GlobalAudio.audio_id = audioData._id.$id
      this.$GlobalAudio.rate = 1
      // 开始播放
      audio.onplay = () => {
        this.$GlobalAudio.isPlay = true
      }
      // 暂停
      audio.onpause = () => {
        this.$GlobalAudio.isPlay = false
      }
      // 停止
      audio.onended = () => {
        this.$GlobalAudio.isPlay = false
        this.endAudioTimer()
        this.cleanCheckTimer()
      }
      // 播放进度改变
      audio.ontimeupdate = () => {
        this.$GlobalAudio.currentPlayLength = audio.currentTime
        if (audio.duration) {
          this.$GlobalAudio.currentTotalLength = audio.duration
        }
        this.$GlobalAudio.currentRate =
            (audio.currentTime / audio.duration) * 100
      }
      audio.play()
      this.startAudioTimer()
      this.checkTime(audioData)
    },
    startAudioTimer() {
      const audio = this.$refs['audioRefApp']
      this.$GlobalAudio.listenLength = 0
      let timeFlag = 0
      this.audioTimer = setInterval(() => {
        const currentTime = audio.currentTime
        if (
            Math.abs(timeFlag - currentTime) < 2 * (this.$GlobalAudio.rate ? this.$GlobalAudio.rate : 1) &&
            timeFlag !== currentTime
        ) {
          console.log('内容收听了一秒')
          this.$GlobalAudio.listenLength++
        }
        timeFlag = currentTime
      }, 1000)
    },
    checkTime(audioData) {
      this.checkTimer = setInterval(() => {
        if (audioData._id && this.$GlobalAudio.listenLength > 0) {
          const param = {
            user_id: getUserId(),
            sample_id: audioData._id.$id,
            length: this.$GlobalAudio.listenLength,
            type: audioData.type
          }
          if (audioData.type) {
            param.type = audioData.type
          }
          audioRecord(param)
          this.$GlobalAudio.listenLength = 0
        }
      }, 5000)
    },
    endAudioTimer() {
      if (this.audioTimer) {
        window.clearInterval(this.audioTimer)
      }
    },
    cleanCheckTimer() {
      if (this.checkTimer) {
        window.clearInterval(this.checkTimer)
      }
    }
  }
}
</script>
<style lang="scss">
html, body {
  margin: 0;
  padding: 0;
}
#app{

}
p {
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  display: none;
}
</style>
<style scoped>

</style>
