import request from '../utils/request'

export function loginByEmailNew(user_email, user_pwd, union_id) {
    return request.post('./index.php', {
        function: 'loginByEmailNew', user_email, user_pwd, union_id
    })
}

export function requestWXUserInfo(code) {
    return request.post('./index.php', {
        function: 'requestWXUserInfo', code
    })
}


export function getUserByUnionid(union_id) {
    return request.post('./index.php', {
        function: 'getUserByUnionid', union_id
    })
}


export function getUser(id) {
    return request.post('./index.php', {
        function: 'getUser', id
    })
}

export function getCompanyInfo() {
    return request.post('./index.php', {
        function: 'getCompanyInfo'
    })
}

export function getAccountSuccessionsV1(account_id,user_id) {
    return request.post('./index.php', {
        function: 'getAccountSuccessionsV1', account_id,user_id
    })
}

export function getAccount(id) {
    return request.post('./index.php', {
        function: 'getAccount', id
    })
}

export function getAudioListByUserAndChannelId(channel_id, user_id) {
    return request.post('./index.php', {
        function: 'getAudioListByUserAndChannelId', channel_id, user_id
    })
}

export function getAlbumAudioListByUserAndChannelId(channel_id, user_id) {
    return request.post('./index.php', {
        function: 'getAlbumAudioListByUserAndChannelId', channel_id, user_id
    })
}

export function getAlbumOrAudioListByChannelId(channel_id, user_id) {
    return request.post('./index.php', {
        function: 'getAlbumOrAudioListByChannelId', channel_id, user_id
    })
}
export function getAlbumOrAudioListByChannelIdV2(channel_id, user_id) {
    return request.post('./index.php', {
        function: 'getAlbumOrAudioListByChannelIdV2', channel_id, user_id
    })
}

export function getAudioSample(user_id, sample_id) {
    return request.post('./index.php', {
        function: 'getAudioSample', user_id, sample_id
    })
}

export function audioRecord(params) {
    return request.post('./index.php', {
        function: 'audioRecord',
        user_id: params.user_id,
        sample_id: params.sample_id,
        length: params.length,
        type: params.type
    })
}

export function updateAudioNote(params) {
    return request.post('./index.php', {
        function: 'updateAudioNote',
        note_id: params.note_id,
        user_id: params.user_id,
        sample_id: params.sample_id,
        work: params.work,
        type: params.type,
    })
}

export function getAudioNote(user_id, sample_id) {
    return request.post('./index.php', {
        function: 'getAudioNote', user_id, sample_id
    })
}
export function getAlbum(album_id) {
    return request.post('./index.php', {
        function: 'getAlbum', album_id
    })
}

export function getAudioQuestionsAndAnswers(audio_id,user_id) {
    return request.post('./index.php', {
        function: 'getAudioQuestionsAndAnswers', audio_id,user_id
    })
}
export function submitAudioQuestionAnswer(params) {
    return request.post('./index.php', {
        function: 'submitAudioQuestionAnswer',
        user_id:params.user_id,
        audio_id:params.audio_id,
        question_id:params.question_id,
        user_answer:params.user_answer,
        correct:params.correct,

    })
}

export function getChannelInfoByIds(channel_ids) {
    return request.post('./index.php', {
        function: 'getChannelInfoByIds', channel_ids
    })
}
export function getSignPackage(url) {
    return request.post('./index.php', {
        function: 'getSignPackage',url
    })
}

export function updateUser (
    id,
    name,
    email,
    password,
    account_id,
    canUploadAudio,
    group_ids,
    employee_active_status
  ) {
    return request.post('./index.php', {
      function: 'updateUser',
      id,
      name,
      email,
      password,
      account_id,
      canUploadAudio,
      group_ids,
      employee_active_status
    })
  }
export function getAccountGroups (account_id) {
  return request.post('./index.php', {
    function: 'getAccountGroups',
    account_id
  })
}
export const getUserConversationList = (params) => request.post('./index.php', {
    ...params,
    function: 'getUserConversationList'
  })
  export const removeConversation = (params) => request.post('./index.php', {
    ...params,
    function: 'removeConversation'
  })

  export const updateConversation = (params) => request.post('./index.php', {
    ...params,
    function: 'updateConversation'
  })
  export const sendConversation = (params) => request.post('/AIChat/index.php', {
    ...params,
    function: 'sendConversation'
  })

  export const initConversationName = (params) => request.post('./index.php', {
    ...params,
    function: 'initConversationName'
  })

  export const sendConversationAndSave = (params) => request.post('/AIChat/index.php', {
    ...params,
    function: 'sendConversationAndSave'
  })


  export const getConversation = (params) => request.post('./index.php', {
    ...params,
    function: 'getConversation'
  })


  export const getAccountIdByDomain = (params) => request.post('./index.php', {
    ...params,
    function: 'getAccountIdByDomain'
  })
