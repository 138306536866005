import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'normalize.css'
import router from './router'
import GlobalAudio from './utils/GlobalAudio'
import Vconsole from 'vconsole'


const userAgentInfo = navigator.userAgent;
const Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPod"];
const getArr = Agents.filter((i) => userAgentInfo.includes(i));
const isMobile = !!getArr.length;

Vue.prototype.$isMobile = isMobile

Vue.prototype.$GlobalAudio = GlobalAudio
Vue.config.productionTip = false
Vue.prototype.$globalVue = new Vue()
Vue.config.ignoredElements = ['wx-open-audio']
Vue.use(ElementUI)
if (process.env.NODE_ENV == 'development') {
    let vConsole = new Vconsole()
    Vue.use(vConsole)
}
Vue.directive('title',{
    inserted:function (el,binding) {
        document.timeline = el.dataset.title
    }
})
new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
