<template>
  <div class="view-root  user-container ">
    <div class="user-top-container">
      <p class="top-title">{{ accountInfo.web_name }}</p>

      <div style="flex: 1;display: flex;align-items: center;justify-content: center ;  padding: 0px 30px;">
        <img src="../../../assets/default_avator1.png" style="width:72px;height: 72px;object-fit: cover">
        <div style="width: 100%;padding-left: 20px;box-sizing: border-box">
          <p class="user-info">{{ userInfo.user_name }}</p>
          <p class="user-info" style="margin-top: 5px;font-size: 14px;">{{ userInfo.user_email }}</p>
          <p class="user-info" style="margin-top: 5px;font-size: 14px;">{{
              userInfo.account_name
            }}</p>
        </div>
      </div>
    </div>
    <el-tabs v-model="activeName" :stretch="true">
      <el-tab-pane name="first" label="我的任务">

        <div style="width: 100%;padding: 0px 30px;box-sizing: border-box">
          <img src="../../../assets/nodata.png" class="nodata">
          <p class="dataStyle">暂无数据</p>
        </div>



      </el-tab-pane>
      <el-tab-pane name="second" label="设置">
        <div style="width: 100%;padding: 0px 30px;box-sizing: border-box">
<!--          <div class="user-item" @click="goAboutUs">-->
<!--            <img src="../../../assets/icon_aboutus.png" style="width: 20px;height: 20px">-->
<!--            <p style="margin-left: 15px">关于我们</p>-->
<!--          </div>-->
<!--          <div class="empty-line"></div>-->
          <div class="user-item" @click="doExit">
            <img src="../../../assets/icon_exit.png" style="width: 20px;height: 20px">
            <p style="margin-left: 15px">退出登录</p>
          </div>
          <div class="empty-line"></div>
        </div>
      </el-tab-pane>
    </el-tabs>

    <el-dialog  title="提示" :visible.sync="showLoginout" width="80%">
      <div style="font-size: 20px;color: black;">您确定要退出登录吗？</div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="showLoginout = false">取消</el-button>
        <el-button type="primary" @click="loginout">确定</el-button>
      </span>

    </el-dialog>


  </div>
</template>

<script>
import {getUser,getAccount} from "../../../api/shuodao";
import {clearUser, getUserId,getAccountId} from "../../../utils/store";
import '../../../assets/common/common.css'
import Cookies from 'js-cookie'

export default {
  name: "UserInfo",
  data() {
    return {
      activeName: 'first',
      userInfo: {},
      accountInfo:{},
      showLoginout:false
    }
  },
  mounted() {
    let active_name = Cookies.get('activeName')
    if (active_name && active_name != '') {
      this.activeName = active_name
    }
    this.fetchData()
  },
  beforeDestroy() {
    Cookies.set('activeName', this.activeName)
  },
  methods: {
    fetchData() {
      getUser(getUserId()).then((res) => {
        this.userInfo = res.data.data
      })

      let account_id = getAccountId()
      getAccount(account_id).then((res) => {
        this.accountInfo = res.data.data

        document.title = this.accountInfo.web_name;

      })
    },
    gotoSettings() {
      this.$router.push('/settings')
    },
    goAboutUs() {
      this.$router.push('/about-us')
    },
    loginout(){

      this.showLoginout = false

      this.$globalVue.$emit('stopAudio', null)
      clearUser()
      this.$router.replace('/login')
    },
    doExit() {

      if (this.$isMobile){
        this.showLoginout = true
      }else{
        this.$alert('您确定要退出登录吗？', '提示', {
          confirmButtonText: '确定',
          callback: action => {
            if (action == 'confirm') {
              this.$globalVue.$emit('stopAudio', null)
              clearUser()
              this.$router.replace('/login')
            }
          }
        })
      }


    }
  }
}
</script>

<style scoped lang="less">


.user-info {
  font-size: 20px;
  font-weight: normal;
  color: white;
}

.user-container {
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: white;
}

.user-top-container {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 180px;
  background-image: url("../../../assets/user-top.png");
  background-color: transparent;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;

  .top-title {
    padding: 0px 0px;
    margin-top: 30px;
    text-align: center;
    color: white;
    font-size: 20px;
    font-weight: bold;
    font-family: PingFang SC-Bold, PingFang SC;
  }
}

/deep/ .el-tabs__item {
  color: #999999;
  font-weight: normal;
}

/deep/ .el-tabs__item.is-active {
  color: #5592FF;
  font-weight: bold;
}

/deep/ .el-tabs__active-bar {
  background-color: #5592FF;
}

.user-item {
  cursor: pointer;
  align-items: center;
  display: flex;
  height: 52px;
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #333333;
}

.empty-line {
  width: 100%;
  height: 1px;
  background-color: #EFEFEF;
  //border: 1px solid #EFEFEF;
}
.dataStyle{
  margin-top: 10px;
  width: 100%;
  text-align: center;
  color: #999999;
}
.nodata{
  margin-top: 50px;
  margin-left: calc( (100% - 160px ) /2 );
  width: 160px;
  height: 131px;
  align-self: center;
  align-items: center;

}

</style>
