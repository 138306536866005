import Vue from 'vue'
import VueRouter from 'vue-router'
import {getUserId} from "../utils/store";

import Entry from '../views/Entry'
import Link from '../views/Link'
import Home from '../views/pages/Home/index'
import Login from '../views/pages/Login/index'
import UserInfo from '../views/pages/User/UserInfo'
import Settings from '../views/pages/User/Settings'
import AboutUs from '../views/pages/User/AboutUs'
import Square from '../views/pages/Square/Square'
import Audio from '../views/pages/Audio/Audio'
import Note from '../views/pages/Note/index'
import Album from '../views/pages/Album/index'
import AddAccount from '../views/pages/AddAccount/index'
import AIChat from '../views/pages/AIChat/index'
import AIChatItem from '../views/pages/AIChat/Chat'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const userAgentInfo = navigator.userAgent;
const Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPod"];
const getArr = Agents.filter((i) => userAgentInfo.includes(i));
const isMobile = !!getArr.length;

//公共的页面
const commonRoutes = []

let routes

if (isMobile) {
    routes = [
        ...commonRoutes
    ]

} else {
    routes = [
        ...commonRoutes
    ]
}

 routes = [
    {
        path: '/',
        component: Entry,
        meta: {
            requiresAuth: false,
            title: '说道'
        },
    },
    {
        path: '/login',
        component: Login,
        meta: {
            requiresAuth: false,
            title: '说道'
        },
    },
    {
        path: '/link',
        component: Link,
        meta: {
            requiresAuth: false,
            title: '说道'
        },
    },
    {
        path: '/home',
        redirect: '/square',
        component: Home,
        meta: {
            requiresAuth: true,
            title: '说道'
        },
        children: [
            {
                path: '/square',
                component: Square,
                meta: {
                    requiresAuth: true,
                    title: '说道'
                },
            },
            {
                path: '/aichat',
                component: AIChat,
                meta: {
                    requiresAuth: true,
                    title: '说道'
                },
            },
            {
                path: '/aichat-item',
                component: AIChatItem,
                meta: {
                    requiresAuth: true,
                    title: '说道'
                },
            },
            {
                path: '/userinfo',
                component: UserInfo,
                meta: {
                    requiresAuth: true,
                    title: '说道'
                },
            },
        ]
    },
    {
        path: '/audio',
        component: Audio,
        meta: {
            requiresAuth: true,
            title: '说道'
        },
    },
    {
        path: '/album',
        component: Album,
        meta: {
            requiresAuth: true,
            title: '说道'
        },
    },
    {
        path: '/note',
        component: Note,
        meta: {
            requiresAuth: true,
            title: '说道'
        },
    },
    {
        path: '/settings',
        component: Settings,
        meta: {
            requiresAuth: true,
            title: '说道'
        },
    },
    {
        path: '/about-us',
        component: AboutUs,
        meta: {
            requiresAuth: true,
            title: '说道'
        },
    },
    {
        path: '/add-account',
        component: AddAccount,
        meta: {
            requiresAuth: false,
            title: '说道'
        },
    },
]
const router = new VueRouter({
    // linkActiveClass: 'active',
    mode: 'history',
    routes,
})
router.beforeEach((to, from, next) => {
    if (to.path === '/login') {
        next()
    } else {
        var userId = getUserId()
        if (to.meta.requiresAuth && (userId == undefined || userId == '')) {
            next({
                path: '/login'
            })
        } else {
            next()
        }
    }
})
export default router
