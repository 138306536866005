<template>
  <div>
    <video ref="videoRef" controlslist="noplaybackrate nodownload" controls
           style="width:100%; height: 300px;display: block;margin: 0 auto;"></video>
  </div>
</template>

<script>
import {audioRecord} from '../api/shuodao'
import {getUserId} from "../utils/store"

export default {
  name: "VideoPlayController",
  props: {
    playData: {
      type: Object,
      default: {}
    },
  },
  watch: {
    playData: {
      handler(newVal, oldVal) {
        this.startPlay()
      },
      immediate: true
    }
  },
  data() {
    return {
      videoRate: 1,
      watchLength: 0,
      videoTimer: ''
    }
  },
  methods: {
    startPlay() {
      this.$globalVue.$emit('stopAudio', null)
      setTimeout(() => {
        this.playVideo()
        this.checkTime()
      }, 1 * 1000)
    },
    playVideo() {
      this.$nextTick(() => {
        const video = this.$refs.videoRef
        // 清除之前的收听
        video.pause()
        this.endVideoTimer()
        // 开始现在的
        video.src = this.playData.audio_list[0].address
        this.videoRate = 1
        // 开始播放
        video.onplay = () => {
          this.isVideoPlay = true
        }
        // 暂停
        video.onpause = () => {
          this.isVideoPlay = false
        }
        // 停止
        video.onended = () => {
          this.isVideoPlay = false
        }
        video.play()
        this.startVideoTimer()
      })
    },
    startVideoTimer() {
      const video = this.$refs.videoRef
      this.watchLength = 0
      let timeFlag = 0
      this.videoTimer = setInterval(() => {
        const currentTime = video.currentTime
        if (
            Math.abs(timeFlag - currentTime) < 2 * (this.videoRate ? this.videoRate : 1) &&
            timeFlag !== currentTime
        ) {
          console.log('内容观看了一秒')
          this.watchLength++
        }
        timeFlag = currentTime
      }, 1000)
    },
    checkTime() {
      this.checkTimer = setInterval(() => {
        if (this.playData._id && this.watchLength > 0) {
          const param = {
            user_id: getUserId(),
            sample_id: this.playData._id.$id,
            length: this.watchLength,
            type: this.playData.type
          }
          if (this.playData.type) {
            param.type = this.playData.type
          }
          audioRecord(param)
          this.watchLength = 0
        }
      }, 5000)
    },
    endVideoTimer() {
      if (this.videoTimer) {
        window.clearInterval(this.videoTimer)
      }
    },
    gotoNoteView() {
      if (this.playData._id) {
        let type = ''
        if (this.playData.type) {
          type = this.playData.type
        }
        let sample_id = this.playData._id.$id
        let title = this.playData.name
        let noteRequireLength = 0
        if (this.playData.noteRequireLength) {
          noteRequireLength = this.playData.noteRequireLength
        }
        this.$router.push({
          path: '/note',
          query: {
            type: type,
            sample_id: sample_id,
            title: title,
            noteRequireLength: noteRequireLength
          }
        })
      }
    },
  },
  beforeDestroy() {
    this.endVideoTimer()
    if (this.checkTimer) {
      window.clearInterval(this.checkTimer)
    }
  },
}
</script>
<style scoped lang="less">
</style>
