<template>
  <div style="width:100%;height: 100vh;position: relative">
    <div class="album-top">
      <Header :title="title" :left-click="goBack" :left-arrow-type="1" :bg-color="'transparent'"
              :title-color="'#ffffff'"/>
      <div style="width: 100%;display: flex;padding: 15px;box-sizing: border-box;margin-top: 10px">
        <img :src="formatAlbumAddress(albumInfo.cover_pic)" style="width: 100px;height: 100px;box-sizing: border-box">
        <div style="flex: 1;padding-left: 15px;padding-top: 10px;box-sizing: border-box">
          <p class="album-name">{{ albumInfo.name }}</p>
          <!-- <p class="album-text" style="margin-top: 15px">{{
              '更新时间：' + formatAlbumUpdateTime(albumInfo.update_time)
            }}</p> -->
        </div>
      </div>
    </div>
    <div style="width: 100%;padding: 5px 30px; box-sizing: border-box;overflow-y: auto">
      <el-tabs v-model="tabName">

        <el-tab-pane label="内容列表" name="first">
          <p class="album_audio_count">共{{ albumInfo.audio_list ? albumInfo.audio_list.length : 0 }}个音频</p>
          <div style="margin-top: 25px" v-if="albumInfo.audio_list">
            <div v-for="(audio,index) in albumInfo.audio_list" class="audio-item" :key="index"
                 @click="gotoAudio(audio)">
              <div style="display:flex;flex-direction: row;align-items: center">
                <img src="../../../assets/audio_play.png" style="width: 40px;height: 40px;object-fit: cover">
                <div style="flex: 1;padding-left: 15px; box-sizing: border-box">
                  <p class="audio_name">{{ audio.name }}</p>
                </div>
              </div>
              <el-divider/>
            </div>
          </div>
        </el-tab-pane>

        <el-tab-pane label="专辑简介" name="second">

          <div style="width: 100%;">
            <p v-if="albumInfo.intro && albumInfo.intro!=''" class="dataStyle" style="text-align: left;line-height: 28px">{{albumInfo.intro}}</p>
            <div v-else >
              <img src="../../../assets/nodata.png" class="nodata">
              <p class="dataStyle">暂无数据</p>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import {getAlbum} from '../../../api/shuodao'
import Header from "../../../components/Header";
import {formatDate} from "../../../utils/date";

export default {
  name: "Album",
  components: {
    Header
  },
  data() {
    return {
      tabName: 'first',
      title: '专辑详情',
      album_id: this.$route.query.album_id,
      albumInfo: {}
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {

    gotoAudio(audio) {
      this.$router.push({
        path: 'audio',
        query: {
          audio_id: audio._id.$id,
          album_id: this.album_id
        }
      })
    },

    formatAlbumAddress(cover_pic) {

      if (cover_pic){

        return cover_pic
      }

      return require('../../../assets/album_default.png')
    },
    fetchData() {
      getAlbum(this.album_id).then((res) => {
        this.albumInfo = res.data
      })
    },
    formatUid(uid) {
      return formatDate(new Date(uid), 'yyyy/MM-dd')
    },
    formatAlbumUpdateTime(update_time) {
      return formatDate(new Date(update_time * 1000), 'yyyy-MM-dd')
    },
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="less">

/deep/ .el-tabs__item {
  color: #999999;
}

/deep/ .el-tabs__item.is-active {
  color: #5592FF;
}

.audio_name {
  padding-right: 5px;
  box-sizing: border-box;
  flex: 1;
  font-size: 16px;
  line-height: 28px;
  font-family: PingFang SC-Bold, PingFang SC;
  color: #333333;
}

.audio-item {
  cursor: pointer;
  width: 100%;
  padding: 0px 0px;
}


.album_audio_count {
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}

.album-desc {
  font-size: 15px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
  line-height: 30px;
}

.album-text {
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
}

.album-name {
  line-height: 32px;
  font-size: 20px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #FFFFFF;
}

.album-top {
  width: 100%;
  height: 220px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-image: url("../../../assets/album_top.png");
  background-size: cover;
}


.dataStyle{
  margin-top: 10px;
  width: 100%;
  text-align: center;
  color: #999999;
}
.nodata{
  margin-top: 50px;
  margin-left: calc( (100% - 160px ) /2 );
  width: 160px;
  height: 131px;
  align-self: center;
  align-items: center;

}

/deep/ .el-tabs__nav-wrap::after {
  background-color: transparent;
}
</style>
