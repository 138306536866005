<template>
  <div class="audio-container">
    <Header :title="audioData.name" :left-click="gotoBack" :left-arrow-type="2" :bg-color="'transparent'"></Header>
    <div style="flex:1;width: 100%;height: 100%">
      <el-tabs v-model="activeName" :stretch="true">
        <el-tab-pane v-if="audioData.content_type=='video'" label="视频播放" name="first">
          <VideoPlayController :play-data="audioData"/>
        </el-tab-pane>
        <el-tab-pane label="内容简介" name="second">
          <div style="width: 100%;padding-left: 20px;box-sizing: border-box">
            <div v-if="audioData.intro" style="margin-top: 10px">
              <p class="audio-tag"> 核心主旨</p>
              <p class="audio-info" style="margin-top: 5px">{{ audioData.intro }}</p>
            </div>
            <div v-if="audioData.creater" style="margin-top: 10px;width: 100%;">
              <p class="audio-tag"> 分享嘉宾</p>
              <div style="margin-top: 10px;display: flex;width: 100%;">
                <img :src="audioData.creater.avatar_pic_url" style="width: 48px;height: 48px;border-radius: 50%;object-fit: cover">
                <div style="flex:1;width: 100%;box-sizing: border-box;align-items: center">
                  <p class="audio-info" style="color: #333;font-weight: bold;font-size: 16px">{{ audioData.creater.creater_legal_name }}</p>
                  <p class="audio-info" style="color: #666;margin-top: 5px">{{ audioData.creater.creater_intro }}</p>
                </div>
              </div>
              <p class="audio-info" v-if="audioData.creater.creater_intro_long" style="margin-top: 10px;color: #666">{{ audioData.creater.creater_intro_long }}</p>

            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="学习笔记" name="third">
          <div style="width: 100%;padding-left: 20px;box-sizing: border-box">
            <!--音频介绍，所属专辑，所属channel ，笔记，点击按钮去添加笔记-->
            <div v-if="albumInfo.name && albumInfo.name!=''" style="display: flex;margin-top: 10px;align-items: center">
              <p class="audio-tag">所属专辑</p>
              <p class="audio-info">{{ albumInfo.name }}</p>
            </div>
            <div v-if="channelInfo" style="display: flex;margin-top: 10px;align-items: center">
              <p class="audio-tag">所属频道</p>
              <p class="audio-info">{{ channelInfo }}</p>
            </div>
            <div v-if="noteInfo" style="display: flex;margin-top: 10px">
              <p class="audio-tag">学习笔记</p>
              <p class="audio-info">{{ noteInfo.work }}</p>
            </div>
            <div style="width: 100%;padding-right: 20px;box-sizing: border-box;margin-top: 30px">
              <div class="edit-note" @click="gotoNoteView">
                <img src="../../../assets/edit_note.png" style="width: 18px;height: auto;margin-right: 10px">
                <p>编辑笔记</p>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="思考练习" name="four">
          <AudioQuestion :audio_id="audio_id"/>
        </el-tab-pane>
      </el-tabs>
    </div>
    <AudioPlayController v-if="audioData.content_type=='audio'" :play-data="audioData"/>

    <div style="width: 100%;height: 20px"></div>

  </div>
</template>

<script>
import '../../../assets/common/common.css'
import Header from "../../../components/Header";
import AudioPlayController from "../../../components/AudioPlayController";
import VideoPlayController from "../../../components/VideoPlayController";
import {getAlbum, getAudioNote, getAudioSample, getChannelInfoByIds} from '../../../api/shuodao'
import {getUserId} from "../../../utils/store";
import AudioQuestion from "../../../components/AudioQuestion";

export default {
  name: "Audio",
  components: {AudioQuestion, AudioPlayController, VideoPlayController, Header},
  data() {
    return {
      activeName: 'second',
      audio_id: this.$route.query.audio_id,
      album_id: this.$route.query.album_id,
      audioData: {},
      albumInfo: {},
      noteInfo: {},
      channelInfo: ''
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      getAudioSample(getUserId(), this.audio_id).then((res) => {
        this.audioData = res.data.data
        if (this.album_id != '') {
          this.requestAlbumInfo()
        }
        if (this.audioData.content_type == 'video') {
          this.activeName = 'first'
        }
        if (this.audioData.channel_id && this.audioData.channel_id.length > 0) {
          this.requestChannel(this.audioData.channel_id)
        }
      })
      getAudioNote(getUserId(), this.audio_id).then((res) => {
        this.noteInfo = res.data.data
      })
    },
    requestChannel(channel_ids) {
      getChannelInfoByIds(channel_ids).then((res) => {
        let result = res.data.data
        if (result && result.length > 0) {
          let length = result.length
          for (let i = 0; i < length; i++) {
            this.channelInfo = result[i].name
            if (i != (length - 1)) {
              this.channelInfo = this.channelInfo + '、'
            }
          }
        }
      })
    },
    requestAlbumInfo() {
      getAlbum(this.album_id).then((res) => {
        this.albumInfo = res.data
      })
    },
    gotoBack() {
      this.$router.go(-1)
    },
    formatAudioAddress(logoAddress) {
      switch (logoAddress) {
        case 'head7.png':
          return require('../../../assets/head7.png')
        case 'head6.png':
          return require('../../../assets/head6.png')
        case 'head5.png':
          return require('../../../assets/head5.png')
        case 'head4.png':
          return require('../../../assets/head4.png')
        case 'head3.png':
          return require('../../../assets/head3.png')
        case 'head2.png':
          return require('../../../assets/head2.png')
        case 'head1.png':
          return require('../../../assets/head1.png')
      }
      return require('../../../assets/head.png')
    },
    gotoNoteView() {
      if (this.audioData._id) {
        let type = ''
        if (this.audioData.type) {
          type = this.audioData.type
        }
        let sample_id = this.audioData._id.$id
        let title = this.audioData.name
        let noteRequireLength = 0
        if (this.audioData.noteRequireLength) {
          noteRequireLength = this.audioData.noteRequireLength
        }
        this.$router.push({
          path: '/note',
          query: {
            type: type,
            sample_id: sample_id,
            title: title,
            noteRequireLength: noteRequireLength
          }
        })
      }
    },
  }
}
</script>

<style scoped lang="less">

/deep/ .el-tabs__item {
  color: #999999;
  font-weight: normal;
}

/deep/ .el-tabs__item.is-active {
  color: #5592FF;
  font-weight: bold;
}

/deep/ .el-tabs__active-bar {
  background-color: #5592FF;
}

.edit-note {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 44px;
  border-radius: 12px 12px 12px 12px;
  border: 2px solid #5592FF;
  box-sizing: border-box;
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #5592FF;
}

.audio-tag {
  text-align: center;
  line-height: 28px;
  width: 92px;
  height: 28px;
  background: #E8F0FF;
  border-radius: 6px 6px 6px 6px;
  font-size: 14px;
  font-family: PingFang SC-Bold, PingFang SC;
  color: #5592FF;
}

.audio-container {
  background-color: white;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.audio-info {
  width: 100%;
  padding-left: 15px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  color: #333333;
  line-height: 22px;
}

</style>
