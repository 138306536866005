<template>
  <div class="audioContainer" @click="toAudioDetail">
    <div class="icon">
      <img
          src="../assets/play.png"
          style="width: 44px; cursor: pointer"
          v-show="!isPlay"
          @click.stop="play"
      />
      <img
          src="../assets/pause.png"
          style="width: 44px; cursor: pointer"
          v-show="isPlay"
          @click.stop="pause"
      />
    </div>
    <div class="center">
      <div class="name">
        {{ this.$GlobalAudio.name ? this.$GlobalAudio.name : '当前没有内容播放' }}
      </div>
      <div class="centerBottom">
        <div class="time">
          {{ formatTimeLength(currentPlayLength) }}/{{
            formatTimeLength(currentTotalLength)
          }}
        </div>
        <div class="process" @click.stop>
          <el-slider
              :max="100"
              @change="changeSlide"
              v-model="currentRate"
              :show-tooltip="false"
          ></el-slider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AudioPlayBar",
  data() {
    return {
      isPlay: this.$GlobalAudio.isPlay,
      currentPlayLength: this.$GlobalAudio.currentPlayLength,
      currentTotalLength: this.$GlobalAudio.currentTotalLength,
      currentRate: this.$GlobalAudio.currentRate,
    }
  },
  mounted() {
    this.$GlobalAudio_watch('currentRate', (res) => {
      this.currentRate = res
    })
    this.$GlobalAudio_watch('currentPlayLength', (res) => {
      this.currentPlayLength = res
    })
    this.$GlobalAudio_watch('currentTotalLength', (res) => {
      this.currentTotalLength = res
    })
    this.$GlobalAudio_watch('isPlay', (res) => {
      this.isPlay = res
    })
  },
  methods: {
    toAudioDetail(){
      this.$router.push({
        path:'/audio',
        query:{
          audio_id:this.$GlobalAudio.audio_id
        }
      })
    },

    play() {
      const audio = this.$GlobalAudio.appAudioRef
      audio.play()
    },

    changeSlide() {
      const audio = this.$GlobalAudio.appAudioRef
      let currentTime = (this.currentRate / 100) * this.currentTotalLength
      // this.$nextTick(()=>{
        audio.currentTime = currentTime
      // })
    },
    pause() {
      const audio = this.$GlobalAudio.appAudioRef
      audio.pause()
    },
    formatTimeLength(time) {
      if (time === 0) {
        return '00:00'
      } else {
        const minute = Math.floor(time / 60)
        const second = Math.floor(time % 60)
        return `${minute < 10 ? '0' + minute : minute}:${
            second < 10 ? '0' + second : second
        }`
      }
    },
  }
}
</script>

<style lang="less" scoped>

/deep/ .el-slider__bar {
  background-color: #FFA700;
}

/deep/ .el-slider__runway {
  background-color: #FFEFD0;
}

/deep/ .el-slider__button {
  border-color: #FFA700;
}

.audioContainer {
  padding: 12px 15px;
  box-sizing: border-box;
  background: #fff;
  display: flex;
  align-items: center;

  .center {
    width: 100%;
    flex: 1;
    box-sizing: border-box;
    padding-left: 10px;

    .name {
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #5592FF;
      font-size: 12px;
    }

    .centerBottom {
      display: flex;
      align-items: center;

      .time {
        width: 72px;
        color: #858585;
        letter-spacing: 1px;
        font-size: 10px;
      }

      .process {
        flex: 1;
        width: 100%;
        box-sizing: border-box;
        padding-left: 15px;
      }
    }
  }
}
</style>
