<template>
  <div style="width: 100%;height: 100%;position: relative">
    <div
        style="position: absolute;display: flex;flex-direction: column;width: 100%;padding: 30px 15px;box-sizing: border-box">
      <div style="display: flex;flex-direction: row;box-sizing: border-box">
        <img src="../../../assets/arrow_black_left.png" style="width: 20px;height: auto;cursor: pointer"
             @click="gotoBack">
      </div>
      <p style="color: #333333;font-size: 16px;font-weight: bold;line-height: 28px;margin-top: 25px">{{ title }}</p>

      <div style="margin-top: 25px">
        <el-input v-model="noteInfo" type="textarea" :rows="10"
                  :placeholder="noteRequireLength>0?'请至少输入'+noteRequireLength+'字数':'请输入内容'"></el-input>
      </div>
      <div style="margin-top:35px;width: 100%;text-align: center">
        <div class="btn-submit" @click="doSubmit">提交</div>
      </div>
    </div>
  </div>
</template>
<script>
import {getAudioNote, updateAudioNote} from '../../../api/shuodao'
import {getUserId} from "../../../utils/store";

export default {
  name: "Note",
  data() {
    return {
      audioNote: '',
      noteInfo: '',
      type: this.$route.query.type,
      sample_id: this.$route.query.sample_id,
      title: this.$route.query.title,
      noteRequireLength: this.$route.query.noteRequireLength
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {

    fetchData() {
      getAudioNote(getUserId(), this.sample_id).then((res) => {
        let result = res.data.data
        if (result) {
          this.audioNote = result
          this.noteInfo = result.work
        }
      })
    },

    doSubmit() {
      let content = this.noteInfo
      if (content == '' || content.length <= 0) {
        this.$message.warning('请输入内容')
        return
      }
      if (this.noteRequireLength > 0 && content.length < this.noteRequireLength) {
        this.$message.warning('请至少输入' + this.noteRequireLength + '个字数')
        return;
      }
      let note_id = ''
      if (this.audioNote && this.audioNote._id) {
        note_id = this.audioNote._id.$id
      }
      let params = {
        note_id: note_id,
        user_id: getUserId(),
        sample_id: this.sample_id,
        work: content,
        type: this.type,
      }
      updateAudioNote(params).then((res) => {
        this.$message.success('提交成功')
        this.gotoBack()
      })
    },

    gotoBack() {
      this.$router.go(-1)
    },
  }
}
</script>

<style scoped lang="less">

/deep/ .el-textarea__inner {
  color: #333333;
  padding: 12px 16px;
  box-sizing: border-box;
  background-color: #F8F8F8;
  border-radius: 6px;
  border-width: 0px;
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 24px;
}

.btn-submit {
  cursor: pointer;
  display: inline-block;
  width: 90%;
  height: 44px;
  text-align: center;
  line-height: 44px;
  background-color: #324DB8;
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #FFFFFF;
  border-radius: 12px 12px 12px 12px;
}

</style>
