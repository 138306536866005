<template>
  <div class="view-root" style="height: 100vh">
    <Header :title="title" :left-click="gotoBack"/>
    <div style="display: flex;flex-direction: column;width: 100%;height: calc(100vh - 52px);">
      <div style="flex: 1;text-align: center;padding-top: 100px;box-sizing: border-box">
        <img src="../../../assets/login_icon.png" style="width: 81px;height: 81px;object-fit: cover">
      </div>
      <div style="width: 100%;text-align: center;padding: 20px 0px;box-sizing: border-box">
        <p class="company-info-text">{{ companyInfo.name }}</p>
        <p class="company-info-text" style="margin-top: 5px"> {{ 'copyright©' + companyInfo.year }}</p>
        <p class="company-info-text" style="margin-top: 5px"> {{ '客服电话：' + companyInfo.phone }}</p>
        <p class="company-info-text" style="margin-top: 5px"> {{ '邮箱：' + companyInfo.email }}</p>
        <p class="company-info-text" style="margin-top: 5px"> {{ '电信增值业务许可证号：' + companyInfo.telecom }}</p>
        <p class="company-info-text" style="margin-top: 5px"> {{ '人力资源服务业务备案凭证：' + companyInfo.record }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../../../components/Header";
import '../../../assets/common/common.css'
import {
  getCompanyInfo
} from '../../../api/shuodao'

export default {
  name: "AboutUs",
  components: {
    Header
  },
  data() {
    return {
      title: '关于我们',
      companyInfo: {}
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      getCompanyInfo().then((res) => {
        this.companyInfo = res.data.data
      })
    },
    gotoBack() {
      this.$router.go(-1)
    },
    goAboutUs() {
      this.$router.push('about-us')
    }
  }
}
</script>

<style scoped>

.company-info-text {
  color: #9698a2;
  font-size: 14px;
}

</style>
